import styled from "styled-components";
import Collapse from "@mui/material/Collapse";

const RatingMessage = ({ open }) => {
  return (
    <Collapse in={open}>
      <MessageWrapper>
        <MessageArrowContainer>
          <MessageArrow />
        </MessageArrowContainer>
        <MessageContentContainer>
          <p>
            RateZip scores are determined by our editorial team and trusted
            third party review websites. We include several factors consumers
            might consider when choosing a financial product or service.
          </p>
        </MessageContentContainer>
      </MessageWrapper>
    </Collapse>
  );
};

const MessageWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MessageArrowContainer = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
`;

const MessageArrow = styled.div`
  width: 0;
  height: 0;
  border-bottom: 15px solid #1c5caf;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
`;

const MessageContentContainer = styled.div`
  border-radius: 15px;
  background-color: #1c5caf;
  padding: 15px;

  > p {
    font-family: "Outfit", sans-serif;
    font-size: 0.8em;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.5em;
    padding: 6px 0px;
    margin: 0px;
    color: #ffff;
  }
`;

export default RatingMessage;
