import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Close from "@mui/icons-material/Close";
import Menu from "@mui/icons-material/Menu";

import BackgroundTheme from "../components/BackgroundTheme";
import BackgroundBall from "../components//BackgroundBall";
import Modal from "../components/Modals";

import * as theme from "../util/theme";
import { useWindowSize } from "../hooks/useWindowSize";

import money_select_logo from "../assets/images/money_select_logo.png";
import money_select_logo_mobile from "../assets/images/money_select _logo_mobile .png";
import hero_img from "../assets/images/money_select_hero_image.png";
import circle_1 from "../assets/images/home_page_circle_1.png";
import circle_2 from "../assets/images/home_page_circle_2.png";
import purchase from "../assets/images/purchase.png";
import refinance from "../assets/images/refinance.png";
import personal from "../assets/images/personal.png";
import saving from "../assets/images/saving.png";
import message_1 from "../assets/images/message_1.png";
import message_2 from "../assets/images/message_2.png";
import message_3 from "../assets/images/message_3.png";

const Home = ({ is_dynamic }) => {
  const advertising_disclosure_ref = React.useRef(null);
  const window_size = useWindowSize();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isSubTextOpen, setIsSubTextOpen] = React.useState(false);

  const handleNavigation = (url) => navigate(url);

  const handleOpenAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleOpen();
  };

  const handleCloseAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleClose();
  };

  const handleToggleSubText = () => setIsSubTextOpen(!isSubTextOpen);

  return (
    <HomeWrapper>
      <Modal ref={advertising_disclosure_ref} is_default_false>
        <AdvertisingDisclosureModalWrapper>
          <AdvertisingDisclosureCloseIconContainer>
            <AdvertisingDisclosureCloseIcon>
              <Close
                sx={{ color: theme.primary }}
                onClick={handleCloseAdvertisingClosureModal}
              />
            </AdvertisingDisclosureCloseIcon>
          </AdvertisingDisclosureCloseIconContainer>
          <AdvertisingDisclosureHeaderContainer>
            <AdvertisingDisclosureHeaderText>
              Advertising Disclosure
            </AdvertisingDisclosureHeaderText>
          </AdvertisingDisclosureHeaderContainer>
          <AdvertisingDisclosureModalContent>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                MoneySelect is a comparison shopping website. We earn revenue
                and advertising compensation from the reviews that we display.
                Our objective is to give consumers a trustworthy way to compare
                the best mortgage lenders in the nation at a given moment, all
                in one spot. All of the reviews are written by MoneySelect
                editorial staff. Inclusion on our comparison pages does not
                imply that we endorse the companies listed.
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                The ranking of each listing is an internal decision made by
                MoneySelect that is based on many factors including the
                reputation of the the lender, the engagement rate of consumers,
                the revenue earned from the lender and the capacity of the
                lender. If you have any concerns or suggestions, please contact
                us!
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            {is_dynamic && (
              <React.Fragment>
                <AdvertisingDisclosureHeaderContainer>
                  <AdvertisingDisclosureHeaderText>
                    Rates
                  </AdvertisingDisclosureHeaderText>
                </AdvertisingDisclosureHeaderContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    The rate information is obtained by Bankrate from the listed
                    institutions. Bankrate cannot guarantee the accuracy or
                    availability of any rates shown. Institutions may have
                    different rates on their own websites than those posted on
                    Bankrate.com. The listings that appear on this page are from
                    companies from which this website receives compensation,
                    which may impact how, where, and in what order products
                    appear. This table does not include all companies or all
                    available products.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Consumer Satisfaction: Bankrate attempts to verify the
                    accuracy and availability of its Advertisers' terms through
                    its quality assurance process and requires Advertisers to
                    agree to our Terms and Conditions and to adhere to our
                    Quality Control Program. If you believe that you have
                    received an inaccurate quote or are otherwise not satisfied
                    with the services provided to you by the institution you
                    choose, please{" "}
                    <a href={"https://www.bankrate.com/contact/"}>
                      click here.
                    </a>
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Rate collection and criteria:{" "}
                    <a
                      href={
                        "https://www.bankrate.com/investing/product-criteria/?prodtype=invest"
                      }
                    >
                      Click here
                    </a>{" "}
                    for more information on rate collection and criteria.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
              </React.Fragment>
            )}
          </AdvertisingDisclosureModalContent>

          <AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentText>
              Sincerely, <br />
              The MoneySelect Team
            </AdvertisingDisclosureContentText>
          </AdvertisingDisclosureContentContainer>
          <AdvertisingDisclosureCloseButtonContainer>
            <AdvertisingDisclosureCloseButton
              onClick={handleCloseAdvertisingClosureModal}
            >
              Close
            </AdvertisingDisclosureCloseButton>
          </AdvertisingDisclosureCloseButtonContainer>
        </AdvertisingDisclosureModalWrapper>
      </Modal>
      <HumbugerMenuContainer>
        <Menu
          sx={{ color: theme.primary }}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
      </HumbugerMenuContainer>
      <SlideMenuContainer
        is_menu_open={isMenuOpen}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />
      <SlideMenu is_menu_open={isMenuOpen}>
        <SlideMenuCloseItem>
          <Close
            sx={{ color: "#fff", paddingRight: "10px" }}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </SlideMenuCloseItem>
        <SlideMenuItem>
          <StyledLink to="/mortgage/1">Mortgage Loans</StyledLink>
        </SlideMenuItem>
        <SlideMenuItem>
          <StyledLink to="/personal_loan_no_default_location">
            Personal loans
          </StyledLink>
        </SlideMenuItem>
        <SlideMenuItem>
          <StyledLink to="/savings/compare">Checking & Savings</StyledLink>
        </SlideMenuItem>
        <SlideMenuItem>
          <StyledLink to="/about-us">About Money Select</StyledLink>
        </SlideMenuItem>
      </SlideMenu>
      <HomeContent>
        {window_size[0] > 515 && (
          <React.Fragment>
            <BackgroundBall size={350} direction={180} left={-5} top={80} />
            <BackgroundBall size={100} direction={300} left={50} top={60} />
            <BackgroundBall size={100} direction={250} left={40} top={80} />
          </React.Fragment>
        )}
        <MessageIcon1Container>
          <MessageIcon src={message_1} alt={"message_1"} />
        </MessageIcon1Container>
        <MessageIcon2Container>
          <MessageIcon src={message_2} alt={"message_2"} />
        </MessageIcon2Container>
        <MessageIcon3Container>
          <MessageIcon src={message_3} alt={"message_3"} />
        </MessageIcon3Container>
        <HomeHeader>
          <AppHeaderLogoContainer>
            <AppLogo
              src={
                window_size[0] < 515
                  ? money_select_logo_mobile
                  : money_select_logo
              }
            />
          </AppHeaderLogoContainer>
          <MenuContainer>
            <StyledLink to="/mortgage/1">Mortgage Loans</StyledLink>
            <StyledLink to="/personal_loan_no_default_location">
              Personal Loans
            </StyledLink>
            <StyledLink to="/about-us">About Money Select</StyledLink>
          </MenuContainer>
        </HomeHeader>
        <MiddleContent>
          <MiddleContentTextContainer>
            <MiddleContentCircleImageContainer>
              <MiddleContentCircleImage src={circle_1} alt={"circle_1"} />
            </MiddleContentCircleImageContainer>
            <MiddleContentCircle2ImageContainer>
              <MiddleContentCircleImage src={circle_2} alt={"circle_2"} />
            </MiddleContentCircle2ImageContainer>
            <MiddleContentText>
              Compare the top <span>financial service</span> offers in the
              nation and take control of your finances.
            </MiddleContentText>
            <MiddleContentSubTextContainer is_open={isSubTextOpen}>
              {isSubTextOpen ? (
                <MiddleContentSubText>
                  When it comes to your money, you want to select the best
                  financial products. Great rates, low fees, amazing customer
                  service – all are important. MoneySelect makes comparing
                  financial institutions easy – just select the product you’re
                  looking for, enter your desired product information, and start
                  comparing offers instantly! <br />
                  MoneySelect lists the top financial companies all across the
                  nation to help consumers take control of their finances. Don’t
                  settle for the first bank you come across – let MoneySelect
                  help you research multiple financial institutions all at once
                  so that you can select the product that’s right for you!
                </MiddleContentSubText>
              ) : (
                <MiddleContentSubText>
                  When it comes to your money, you want to select the best
                  financial products.
                </MiddleContentSubText>
              )}
            </MiddleContentSubTextContainer>
            <ReadMoreContainer>
              <ReadMoreContainerText onClick={handleToggleSubText}>
                Read {isSubTextOpen ? "Less" : "More"} ...{" "}
              </ReadMoreContainerText>
            </ReadMoreContainer>
            <ButtonContainer>
              <StyledButton
                onClick={() => handleNavigation("/mortgage/2/purch")}
                startIcon={
                  <ButtonIconContainer>
                    <ButtonIcon src={purchase} alt={"purchase"} />
                  </ButtonIconContainer>
                }
              >
                <div>
                  <p>HOME</p>
                  <p>PURCHASE</p>
                </div>
              </StyledButton>
              <StyledButton
                onClick={() => handleNavigation("/mortgage/2/refi")}
                startIcon={
                  <ButtonIconContainer>
                    <ButtonIcon src={refinance} alt={"refinance"} />
                  </ButtonIconContainer>
                }
              >
                <div>
                  <p>HOME</p>
                  <p>REFINANCE</p>
                </div>
              </StyledButton>
              <StyledButton
                onClick={() =>
                  handleNavigation("/personal_loan_no_default_location")
                }
                startIcon={
                  <ButtonIconContainer>
                    <ButtonIcon src={personal} alt={"personal"} />
                  </ButtonIconContainer>
                }
              >
                <div>
                  <p>PERSONAL</p>
                  <p>LOANS</p>
                </div>
              </StyledButton>
              <StyledButton
                onClick={() => handleNavigation("/savings/compare")}
                startIcon={
                  <ButtonIconContainer>
                    <ButtonIcon src={saving} alt={"saving"} />
                  </ButtonIconContainer>
                }
              >
                <div>
                  <p>
                    CHECKING<span>&</span>
                  </p>
                  <p>SAVINGS</p>
                </div>
              </StyledButton>
            </ButtonContainer>
          </MiddleContentTextContainer>
          <MiddleContentImageContainer>
            <HeroImageContainer>
              <HeroImage src={hero_img} alt={"hero_image"} />
            </HeroImageContainer>
            <BackgroundCircle />
          </MiddleContentImageContainer>
        </MiddleContent>
        <Footer>
          <FooterContent>
            <FooterDown>
              <FooterDownText>
                © 2022 Moneyselect.com. All Rights Reserved. <br />
                <span onClick={handleOpenAdvertisingClosureModal}>
                  Advertising Disclosure
                </span>{" "}
                |{" "}
                <span
                  onClick={() => {
                    navigate(`/1/privacy_and_security`);
                  }}
                >
                  Privacy & Security
                </span>
              </FooterDownText>
            </FooterDown>
          </FooterContent>
        </Footer>
      </HomeContent>
      <HomeMask />
      <BackgroundTheme />
      <BackgroundBall size={600} direction={45} left={50} top={30} />
      <BackgroundBall size={350} direction={45} left={20} top={10} />
      <HomeBackgroundTheme />
    </HomeWrapper>
  );
};

const HomeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const HomeBackgroundTheme = styled.div`
  background: rgb(152, 170, 133);
  background: linear-gradient(
    90deg,
    rgba(152, 170, 133, 0.5) 0%,
    rgba(185, 199, 169, 0.5) 12%,
    rgba(234, 244, 223, 0.5) 39%,
    rgba(236, 246, 226, 0.5) 67%,
    rgba(237, 244, 230, 0.5) 94%,
    rgba(255, 255, 255, 0.5) 100%
  );
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  position: absolute;
`;

const HomeMask = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  backdrop-filter: blur(30px);
`;

const HomeContent = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 100;
`;

const HomeHeader = styled.div`
  width: 90%;
  margin: 0px auto;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AppHeaderLogoContainer = styled.div`
  width: 100px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 515px) {
    width: 70px;
    height: 70px;
  }
`;

const AppLogo = styled.img`
  width: 100%;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0px;
  @media (max-width: 515px) {
    display: none;
  }
`;

const MiddleContent = styled.div`
  width: 90%;
  height: calc(100vh - 180px);
  margin: 0px auto;
  display: flex;
  justify-content: stretch;
  align-items: center;
`;

const MiddleContentTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 40%;
  margin: 0px 10% 0px 0px;
  height: 80vh;
  position: relative;
  transform: translateY(80px);

  @media (max-width: 1280px) {
    transform: translateY(20px);
  }

  @media (max-width: 1136px) {
    transform: translateY(0px);
  }

  @media (max-width: 515px) {
    width: 100%;
    transform: translateY(10px);
    margin: 0px 0px 0px 0px;
  }
`;

const MiddleContentCircleImageContainer = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  top: 10px;
  right: -80px;
  transform: rotate(75deg);
  @media (max-width: 515px) {
    z-index: -10;
  }
`;

const MiddleContentCircle2ImageContainer = styled(
  MiddleContentCircleImageContainer
)`
  width: 70px;
  height: 70px;
  top: 50px;
  left: -30px;
  z-index: -10;
`;

const MiddleContentCircleImage = styled.img`
  width: 100%;
`;

const MiddleContentText = styled.h2`
  font-weight: 600;
  line-height: 1.2;
  font-size: 2.4rem;
  margin: 0px;
  letter-spacing: -1px;
  font-family: "Yantramanav", sans-serif;

  > span {
    color: ${theme.primary};
  }

  @media (max-width: 1136px) {
    font-size: 2rem;
  }

  @media (max-width: 515px) {
    font-size: 1.7rem;
    text-align: left;
    letter-spacing: 0px;
  }
`;

const MiddleContentSubTextContainer = styled.div`
  @media (max-width: 515px) {
    height: ${(p) => (p.is_open ? "30vh" : "60px")};
    overflow-y: ${(p) => (p.is_open ? "scroll" : "hidden")};
    transition: height 0.2s ease-in;
  }
`;

const ReadMoreContainer = styled.div`
  display: none;
  @media (max-width: 515px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const ReadMoreContainerText = styled.p`
  font-family: "Yantramanav", sans-serif;
  color: #001301;
  text-decoration: underline;
  cursor: pointer;
`;

const MiddleContentSubText = styled.h4`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  margin: 10px 0px;
  font-family: "Yantramanav", sans-serif;
  @media (max-width: 515px) {
    font-size: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 50px 0px 0px 0px;

  @media (max-width: 1280px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px 0px 0px 0px;
  }
`;

const MiddleContentImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50%;

  @media (max-width: 515px) {
    position: absolute;
    opacity: 0.2;
    z-index: -10;
    right: -50vw;
  }
`;

const HeroImageContainer = styled.div`
  width: 50vw;
  height: 50vw;
  position: absolute;
  top: -18vw;
  right: 5vw;
  z-index: 1;

  @media (max-width: 515px) {
    width: 150vw;
    height: 150vw;
  }
`;

const HeroImage = styled.img`
  width: 100%;
`;

const BackgroundCircle = styled.div`
  position: absolute;
  top: -18vw;
  right: 5vw;
  width: 400px;
  height: 400px;
  border-radius: 200px;
  background: rgb(123, 125, 224);
  background: linear-gradient(
    25deg,
    rgba(152, 170, 133, 1) 0%,
    rgba(185, 199, 169, 1) 12%,
    rgba(234, 244, 223, 1) 100%
  );
  z-index: 0;
`;

const StyledButton = styled(Button)`
  background-color: #f48507 !important;
  padding: 15px !important;
  border-radius: 16px !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  font-family: "Yantramanav", sans-serif !important;
  font-size: 1rem !important;
  transition: 0.5s;

  > div {
    padding-left: 5px;
    display: flex;
    flex-direction: column;

    > p {
      text-align: start;
      margin: 0px;
      > span {
        font-size: 0.9rem;
        padding-left: 2px;
      }
    }
  }

  &:hover {
    transition: 0.5s;
    background-color: ${theme.primary_double_light} !important;
  }

  @media (max-width: 515px) {
    height: 100px !important;
    font-size: 1.25rem !important;
  }
`;

const ButtonIconContainer = styled.div`
  width: 25px;
  height: 25px;
  transform: translateY(-2px);
`;

const ButtonIcon = styled.img`
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-size: 1em;
  color: ${theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Yantramanav", sans-serif;
  font-weight: 900;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    color: ${theme.primary_light} !important;
  }
  padding-left: 20px;

  @media (max-width: 515px) {
    padding: 0px;
    color: #fff;
    text-decoration: none;
    font-size: 1.75em;
  }
`;

const MessageIcon1Container = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  top: 8vw;
  right: 5vw;
  z-index: 5;
  @media (max-width: 515px) {
    width: 40px;
    height: 40px;
    top: 20vw;
  }
`;

const MessageIcon2Container = styled(MessageIcon1Container)`
  width: 100px;
  height: 100px;
  top: 76vh;
  left: 36vw;
  z-index: -1;
  @media (max-width: 515px) {
    display: none;
  }
`;

const MessageIcon3Container = styled(MessageIcon1Container)`
  width: 180px;
  height: 180px;
  top: 45vw;
  right: 20vw;
  z-index: 10;
  @media (max-width: 515px) {
    display: none;
  }
`;

const MessageIcon = styled.img`
  width: 100%;
`;

const SlideMenuContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: ${(p) => (p.is_menu_open ? "0px" : "-100vw")};
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: left 0.2s ease-in;
`;

const SlideMenu = styled.div`
  position: absolute;
  top: 0px;
  right: ${(p) => (p.is_menu_open ? "0px" : "-70vw")};
  width: 70vw;
  height: 100vh;
  background-color: ${theme.primary};
  z-index: 1001;
  transition: right 0.5s ease-in;
`;

const SlideMenuItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #fff;
`;

const SlideMenuCloseItem = styled(SlideMenuItem)`
  justify-content: flex-end;
  height: 40px;
`;

const HumbugerMenuContainer = styled.div`
  display: none;

  @media (max-width: 515px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 75px;
    height: 75px;
    z-index: 500;
  }
`;

const Footer = styled.div`
  height: 90px;
  background: ${theme.primary_light};
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 515px) {
    width: 100vw;
  }
`;

const FooterContent = styled.div`
  padding: 0.5rem 0px;
  justify-content: center;
`;

const FooterDown = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 200;
`;

const FooterDownText = styled.p`
  color: #5f5f5f;
  font-size: 0.8em;
  text-align: center;
  > span {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AdvertisingDisclosureModalWrapper = styled.div`
  width: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 25%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  outline: 0;
  padding: 1rem;
  max-height: 90vh;

  @media (max-width: 515px) {
    width: 87%;
    left: 2%;
  }
`;

const AdvertisingDisclosureModalContent = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const AdvertisingDisclosureCloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const AdvertisingDisclosureCloseIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AdvertisingDisclosureHeaderContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureHeaderText = styled.h5`
  font-weight: 500;
  margin: 0px;
  font-size: 1.25rem;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureContentContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureContentText = styled.p`
  font-size: 0.8em;
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AdvertisingDisclosureCloseButton = styled(Button)`
  background-color: ${theme.primary} !important;
  border: none;
  color: #fff !important;
  width: 75px;
  height: 36px;
  text-transform: capitalize;
  font-weight: 600 !important;
`;

export default Home;
