import React from "react";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";

import { fetchStaticOfferData, fetchZipCodeFromIPAddress } from "../util/api";

import Basic from "../components/Basic";
import Message from "../components/Message";
import ListItem from "../components/ListItem";

const StaticOfferBasic = ({ ppcid, gclid, mscklid, fbclid, cid }) => {
  const msg_ref = React.useRef(null);
  let { default_loan_type } = useParams();
  const [state, setState] = React.useState({
    loading: false,
    zip_code: "",
    loan_type: "",
    loan_amount: 400000,
    property_val: "",
    credit_score: 691,
    time_frame: "",
    data: [],
  });

  const handleCheckIsGradualDataHide = (number, selected_number) => {
    if (selected_number) {
      if (+selected_number < +number) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleCheckIsDataHide = (arr = [], selected_val) => {
    // console.log(arr);
    if (selected_val) {
      for (let i = 0; i <= arr.length; i++) {
        if (arr[i] === selected_val) {
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  };

  const handleTraceClickDropDown = (val) => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleTraceClickDropDown(name);

    setState((state) => ({ ...state, [name]: value }));
  };

  React.useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        ppc_lander: "React Comparison Table",
        lander_type: "Static",
        product_name: "default",
        event: "comparisonTableLoad",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    if (default_loan_type) {
      setState((state) => ({ ...state, loan_type: default_loan_type }));
    }
  }, [default_loan_type]);

  React.useEffect(() => {
    const fetchData = async () => {
      setState((state) => ({ ...state, loading: true }));

      const res = await fetchStaticOfferData();

      setState((state) => ({ ...state, data: res.data, loading: false }));
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const handleFetchPostalCode = async () => {
      setState((state) => ({ ...state, loading: true }));
      const postal_code = await fetchZipCodeFromIPAddress(true);

      setState((state) => ({
        ...state,
        zip_code: postal_code,
        loading: false,
      }));
    };
    handleFetchPostalCode();
  }, []);

  React.useEffect(() => {
    const handleUpdateData = async () => {
      setState((state) => ({ ...state, loading: true }));
      const res = await fetchStaticOfferData();
      const update_data = [];
      res.data.forEach((i) => {
        if (
          handleCheckIsDataHide(i.stateHide, state.zip_code) &&
          handleCheckIsDataHide(i.purposeHide, state.loan_type) &&
          handleCheckIsGradualDataHide(i.loanAmountHide, state.loan_amount) &&
          handleCheckIsGradualDataHide(i.creditHide, state.credit_score)
        ) {
          update_data.push(i);
        }
      });

      setState((state) => ({ ...state, data: update_data, loading: false }));
    };

    handleUpdateData();
  }, [
    state.zip_code,
    state.loan_type,
    state.loan_amount,
    state.time_frame,
    state.credit_score,
  ]);

  let list_content;

  if (state.loading) {
    list_content = <div className="loader" />;
  } else {
    list_content = state.data
      .sort((a, b) => b.order - a.order)
      // .filter((i) => !i.hideCard)
      .map((i, index) => {
        let is_show_collapse;
        let is_show_detail_btn;

        if (i.bullets.length > 0) {
          is_show_detail_btn = true;
        } else {
          is_show_detail_btn = false;
        }

        return (
          <ListItem
            key={i.id}
            id={i.id}
            index={index + 1}
            logo={i.logo}
            bullets={i.bullets}
            is_show_collapse={is_show_collapse}
            is_show_detail_btn={is_show_detail_btn}
            nmls={i.nmls}
            phone={i.phone}
            link={i.link}
            rating={i.rating}
            stateHide={i.stateHide}
            creditHide={i.creditHide}
            purchTimeframe={i.purchTimeframe}
            purposeHide={i.purposeHide}
            sub_head_line={i.subHeadline}
            head_line={i.headline}
            ppcid={ppcid}
            gclid={gclid}
            mscklid={mscklid}
            fbclid={fbclid}
            cid={cid}
            lender_name={i.__comment}
          />
        );
      });
  }

  return (
    <React.Fragment>
      <Message ref={msg_ref} message={"no data was found"} type={"warning"} />
      <Basic
        list_content={list_content}
        loading={state.loading}
        handleChange={handleChange}
        parent_state={state}
        is_show_location={true}
        is_show_loan_type={true}
        is_show_credit_score={true}
        is_show_loan_amount={true}
        is_show_timeframe={false}
        search_console_item_num={4}
        is_show_veteran={false}
      />
    </React.Fragment>
  );
};

export default StaticOfferBasic;
