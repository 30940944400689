import React from "react";
import styled from "styled-components";
import TagManager from "react-gtm-module";

import { fetchStaticOfferData, fetchZipCodeFromIPAddress } from "../util/api";

import Basic from "../components/Basic";
import ListItem from "../components/ListItem";
import Modal from "../components/Modals";
import Message from "../components/Message";

import purch from "../assets/images/purch.png";
import refi from "../assets/images/refi.png";
import * as theme from "../util/theme";

const StaticOfferBasic = ({ ppcid, gclid, mscklid, fbclid, cid }) => {
  const modal_ref = React.useRef(null);
  const msg_ref = React.useRef(null);
  const [state, setState] = React.useState({
    loading: false,
    zip_code: "",
    loan_type: "",
    loan_amount: 400000,
    property_val: "",
    credit_score: 691,
    time_frame: "",
    data: [],
  });

  const handleTraceModalButton = (val) => {
    const tagManagerArgs = {
      dataLayer: {
        product_name: `${val}_static`,
        event: "reactMortgageModalClick",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const handleUpdateLoanType = (val) => {
    const display_val = val === "refi" ? "refinance" : "purchase";
    handleTraceModalButton(display_val);
    setState((state) => ({ ...state, loan_type: val }));
    modal_ref.current.handleClose();
  };

  const handleCheckIsGradualDataHide = (number, selected_number) => {
    if (selected_number) {
      if (+selected_number < +number) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleCheckIsDataHide = (arr = [], selected_val) => {
    // console.log(arr);
    if (selected_val) {
      for (let i = 0; i <= arr.length; i++) {
        if (arr[i] === selected_val) {
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  };

  const handleTraceClickDropDown = (val) => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleTraceClickDropDown(name);

    setState((state) => ({ ...state, [name]: value }));
  };

  React.useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        ppc_lander: "React Comparison Table",
        lander_type: "Static",
        product_name: "Modal",
        event: "comparisonTableLoad",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      setState((state) => ({ ...state, loading: true }));

      const res = await fetchStaticOfferData();

      setState((state) => ({ ...state, data: res.data, loading: false }));
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const handleFetchPostalCode = async () => {
      setState((state) => ({ ...state, loading: true }));
      const postal_code = await fetchZipCodeFromIPAddress(true);

      setState((state) => ({
        ...state,
        zip_code: postal_code,
        loading: false,
      }));
    };
    handleFetchPostalCode();
  }, []);

  React.useEffect(() => {
    const handleUpdateData = async () => {
      setState((state) => ({ ...state, loading: true }));
      const res = await fetchStaticOfferData();
      const update_data = [];
      res.data.forEach((i) => {
        if (
          handleCheckIsDataHide(i.stateHide, state.zip_code) &&
          handleCheckIsDataHide(i.purposeHide, state.loan_type) &&
          handleCheckIsGradualDataHide(i.creditHide, state.credit_score) &&
          handleCheckIsGradualDataHide(i.loanHide, state.loan_amount)
        ) {
          update_data.push(i);
        }
      });

      setState((state) => ({ ...state, data: update_data, loading: false }));
    };

    handleUpdateData();
  }, [
    state.zip_code,
    state.loan_type,
    state.loan_amount,
    state.time_frame,
    state.credit_score,
  ]);

  let list_content;

  if (state.loading) {
    list_content = <div className="loader" />;
  } else {
    list_content = state.data
      .sort((a, b) => b.order - a.order)
      .filter((i) => !i.hideCard)
      .map((i, index) => {
        let is_show_collapse;
        let is_show_detail_btn;

        if (i.bullets.length > 0) {
          is_show_detail_btn = true;
        } else {
          is_show_detail_btn = false;
        }

        return (
          <ListItem
            key={i.id}
            id={i.id}
            index={index + 1}
            logo={i.logo}
            bullets={i.bullets}
            is_show_collapse={is_show_collapse}
            is_show_detail_btn={is_show_detail_btn}
            nmls={i.nmls}
            phone={i.phone}
            link={i.link}
            rating={i.rating}
            stateHide={i.stateHide}
            creditHide={i.creditHide}
            purchTimeframe={i.purchTimeframe}
            purposeHide={i.purposeHide}
            sub_head_line={i.subHeadline}
            head_line={i.headline}
            ppcid={ppcid}
            gclid={gclid}
            mscklid={mscklid}
            fbclid={fbclid}
            cid={cid}
            lender_name={i.__comment}
          />
        );
      });
  }

  return (
    <React.Fragment>
      <Message ref={msg_ref} message={"no data was found"} type={"warning"} />
      <Modal ref={modal_ref}>
        <ModalContainer>
          <ModalHeaderContainer>
            <ModalHeaderText>
              What type of <span>home loan</span> are you looking for?
            </ModalHeaderText>
          </ModalHeaderContainer>
          <ModalButtonContainer>
            <StyledButton
              className={"modal_refi_button_static"}
              onClick={() => handleUpdateLoanType("refi")}
            >
              <ButtonLogo alt="button_icon_refinance" src={refi} />
              <p>Refinance</p>
            </StyledButton>
            <StyledButton
              className={"modal_purchase_button_static"}
              onClick={() => handleUpdateLoanType("purch")}
            >
              <ButtonLogo alt="button_icon_purchase" src={purch} />
              <p>purchase</p>
            </StyledButton>
          </ModalButtonContainer>
          <DescriptionContainer>
            <DescriptionText>
              By using our website, you agree that you have read RateZip's Terms
              of Use and Privacy Notice and consent to both the processing of
              your personal information and the storing of cookies on your
              device to enhance site navigation, analyze site usage, and assist
              in our marketing efforts
            </DescriptionText>
          </DescriptionContainer>
        </ModalContainer>
      </Modal>
      <Basic
        list_content={list_content}
        loading={state.loading}
        handleChange={handleChange}
        parent_state={state}
        is_show_location={true}
        is_show_loan_type={true}
        is_show_credit_score={true}
        is_show_loan_amount={true}
        is_show_timeframe={false}
        search_console_item_num={4}
        is_show_veteran={false}
      />
    </React.Fragment>
  );
};

const ModalContainer = styled.div`
  width: 40%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  position: absolute;
  top: 20%;
  left: 30%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1300px) {
    width: 60%;
    left: 20%;
  }

  @media (max-width: 515px) {
    width: 90vw;
    left: 5vw;
  }
`;

const ModalHeaderContainer = styled.div`
  @media (max-width: 515px) {
    width: 90%;
  }
`;

const ModalHeaderText = styled.h5`
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  > span {
    color: ${theme.primary};
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  @media (max-width: 515px) {
    flex-direction: column;
  }
`;

const StyledButton = styled.div`
  display: inline-block;
  color: #ffff;
  padding: 10px 15px;
  text-transform: uppercase;
  vertical-align: center;
  margin: 10px 30px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 0px;
  display: flex;
  align-items: center;
  background-color: ${theme.button_orange} !important;

  border-radius: 14px;

  > p {
    margin: 10px 0px;
    font-size: 1.3em;
    padding-left: 5px;
  }
`;

const ButtonLogo = styled.img`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  padding: 0px 15px 15px 15px;
`;

const DescriptionText = styled.p`
  color: #5f5f5f;
  font-size: 0.8em;
`;

export default StaticOfferBasic;
