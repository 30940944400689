import axios from "axios";

export const fetchToken = async () => {
  const res = await axios({
    method: "post",
    url: `https://banking-auth-proxy.bankrate.com/oauth/token`,
    data: {
      audience: "https://mortgage-api.bankrate.com",
      grant_type: "client_credentials",
      client_id: "oxwuTuXlseWty2abDSUcnQELdi0tfVKY",
      client_secret:
        "_rsXfZgWzIgimh0yZF7d_pusXKQkHQMjN9JEyxRN3zGV7-BptdFtfNgCrnCQlYZa",
    },
  });

  if (res.status === 200) {
    return {
      message: "success",
      token: res.data.access_token,
    };
  } else {
    return {
      message: "fail",
      token: "",
    };
  }
};

export const fetchData = async (
  token,
  loanType,
  propertyValue,
  loanAmount,
  zipCode,
  creditScore,
  military
) => {
  try {
    const res = await axios({
      method: "get",
      url: `https://mortgage-partner-api.bankrate.com/rates/v4?loanType=${loanType}&propertyValue=${propertyValue}&loanAmount=${loanAmount}&zipCode=${zipCode}&productFamilies%5B%5D=conventional&deviceTypes%5B%5D=desktop&deviceTypes%5B%5D=mobile&propertyType=SingleFamily&propertyUse=PrimaryResidence&loanTerms%5B%5D=30yr&cashOutAmount=0&firstTimeHomeBuyer=false&veteranStatus=${military}&hasVaDisabilities=false&hadPriorVaLoan=false&pointsRange=All&defaultSearch=false&creditScore=${creditScore}&includeEditorial=false&disableCache=false&includeSponsored=false&e2eTestEnabled=false&debug=false&pid=myfi_ratezip`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      message: "success",
      data: res.data,
    };
  } catch (e) {
    return {
      message: "fail",
      data: [],
    };
  }
};

export const fetchZipCodeFromIPAddress = async (is_use_iso) => {
  const res = await axios({
    method: "get",
    url: `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_ABSTRACT_API_KEY}`,
  });

  if (res.status === 200) {
    if (res.data.country_code !== "US") {
      return is_use_iso ? "AL" : 29707;
    } else {
      return is_use_iso ? res.data.region_iso_code : res.data.postal_code;
    }
  } else {
    return 29707;
  }
};

export const fetchStaticOfferData = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `https://ratezip-comparison-table-default-rtdb.firebaseio.com/data.json`,
    });

    if (res.status === 200) {
      //where you reorganize your data
      const update_data = [];
      Object.entries(res.data).forEach((i) => {
        update_data.push({
          id: i[0],
          ...i[1],
        });
      });

      return {
        message: "success",
        data: update_data,
      };
    } else {
      return {
        message: "fail",
        data: [],
      };
    }
  } catch (e) {
    return {
      message: "fail",
      data: [],
    };
  }
};

export const fetchPersonalLoamStaticOfferData = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `https://ratezip-comparison-table-default-rtdb.firebaseio.com//personal_loan_data.json`,
    });

    if (res.status === 200) {
      //where you reorganize your data
      const update_data = [];
      Object.entries(res.data).forEach((i) => {
        update_data.push({
          id: i[0],
          ...i[1],
        });
      });

      return {
        message: "success",
        data: update_data,
      };
    } else {
      return {
        message: "fail",
        data: [],
      };
    }
  } catch (e) {
    return {
      message: "fail",
      data: [],
    };
  }
};
