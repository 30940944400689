import styled from "styled-components";

import card from "../assets/images/credit_card.png";
import donut from "../assets/images/3d_circle.png";

const BackgroundImage = ({ width, direction, top, left, bottom, is_donut }) => {
  return (
    <BackgroundImageWrapper
      width={width}
      direction={direction}
      top={top}
      left={left}
      bottom={bottom}
    >
      <BackgroundImageImage
        src={is_donut ? donut : card}
        alt={"credit card image"}
      />
    </BackgroundImageWrapper>
  );
};

const BackgroundImageWrapper = styled.div`
  position: absolute;
  z-index: -38;
  top: ${(p) => `${p.top}vh`};
  bottom: ${(p) => `${p.bottom}vh`};
  left: ${(p) => `${p.left}vw`};
  width: ${(p) => `${p.width}px`};
  height: ${(p) => `${p.width * 0.5683958333333333}px`};
  transform: ${(p) => `rotate(${p.direction}deg)`};
  backdrop-filter: blur(2px);
`;

const BackgroundImageImage = styled.img`
  width: 100%;
`;

export default BackgroundImage;
