import React from "react";
import styled, { keyframes } from "styled-components";
import { useWindowSize } from "../hooks/useWindowSize";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TagManager from "react-gtm-module";

import star_mask from "../assets/images/star_mask.png";
import * as theme from "../util/theme";

const ListItem = ({
  key,
  id,
  index,
  logo,
  nmls,
  phone,
  link,
  rating,
  apr,
  monthly_payment,
  upfront_fees,
  is_show_detail_btn,
  star_rating,
  lender_name,
}) => {
  const window_size = useWindowSize();
  const [state, setState] = React.useState({
    is_show_more: false,
    is_mobile_show_detail: true,
    is_show_arrow: false,
  });

  const handleToggleButtonArrowIcon = () => {
    setState((state) => ({ ...state, is_show_arrow: !state.is_show_arrow }));
  };

  const handleToggleMobileContent = () => {
    setState((state) => ({
      ...state,
      is_mobile_show_detail: !state.is_mobile_show_detail,
    }));
  };

  const handleTraceInfo = () => {
    const tagManagerArgs = {
      dataLayer: {
        lender_name: `${lender_name}(dynamic)`,
        event: "reactMortgageClick",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    console.log(link);
    window.open(link, "_blank");
  };

  React.useEffect(() => {
    if (window_size[0] > 515) {
      setState((state) => ({ ...state, is_mobile_show_detail: true }));
    }
  }, [window_size]);

  return (
    <ListItemContainer key={id}>
      <IndexContainer>
        <IndexText>{index}</IndexText>
      </IndexContainer>
      <ListItemLeft>
        <ListItemLeftLogoContainer>
          <ListItemLeftLogo src={logo} />
        </ListItemLeftLogoContainer>
        <ListItemLeftNSMLContainer>
          <ListItemLeftNSMLText>NMLS #{nmls}</ListItemLeftNSMLText>
        </ListItemLeftNSMLContainer>
        <ListItemLeftStarsListContainer>
          <ListItemLeftStarsFilledListContainer>
            <ListItemLeftStarsFilledListStarMask
              src={star_mask}
              alt={"star_mask"}
            />
            <ListItemLeftStarsFilled
              width={(star_rating / 5) * 160}
              color={theme.primary}
              z_index={2}
            />
            <ListItemLeftStarsFilled
              width={160}
              color={theme.primary_double_light}
              z_index={1}
            />
          </ListItemLeftStarsFilledListContainer>
        </ListItemLeftStarsListContainer>
      </ListItemLeft>
      <MobileToggleDetailContainer
        is_show={is_show_detail_btn}
        onClick={handleToggleMobileContent}
        className={"show_less_and_more_button"}
      >
        {state.is_mobile_show_detail ? "hide detail" : "show detail"}
      </MobileToggleDetailContainer>
      <Collapse
        in={state.is_mobile_show_detail}
        sx={window_size[0] > 515 ? { width: "60%" } : { width: "100%" }}
      >
        <ListItemMiddle>
          <ContentPreviewContainer>
            <ContentPreviewContainerItem>
              <ContentPreviewContainerItemHeadText>
                Rate
              </ContentPreviewContainerItemHeadText>
              <ContentPreviewContainerItemText>
                {rating} <span>%</span>
              </ContentPreviewContainerItemText>
            </ContentPreviewContainerItem>
            <ContentPreviewContainerItem>
              <ContentPreviewContainerItemHeadText>
                APR
              </ContentPreviewContainerItemHeadText>
              <ContentPreviewContainerItemText>
                {apr} <span>%</span>
              </ContentPreviewContainerItemText>
            </ContentPreviewContainerItem>
            <ContentPreviewContainerItem>
              <ContentPreviewContainerItemHeadText>
                Upfront Fees
              </ContentPreviewContainerItemHeadText>
              <ContentPreviewContainerItemText>
                <span>$</span> {upfront_fees}
              </ContentPreviewContainerItemText>
            </ContentPreviewContainerItem>
            <ContentPreviewContainerItem>
              <ContentPreviewContainerItemHeadText>
                Mo. Payment
              </ContentPreviewContainerItemHeadText>
              <ContentPreviewContainerItemText>
                <span>$</span> {monthly_payment}
              </ContentPreviewContainerItemText>
            </ContentPreviewContainerItem>
          </ContentPreviewContainer>
          <ContentResultContainer>
            <ContentResultContainerText>
              Results For: 30 Year Fixed, $300K Loan Amount
            </ContentResultContainerText>
          </ContentResultContainer>
        </ListItemMiddle>
      </Collapse>
      <ListItemRight>
        <ViewRateButton
          onMouseEnter={handleToggleButtonArrowIcon}
          onMouseLeave={handleToggleButtonArrowIcon}
          endIcon={state.is_show_arrow ? <ArrowForwardIcon /> : null}
          is_show_arrow={state.is_show_arrow}
          onClick={handleTraceInfo}
        >
          view rate
        </ViewRateButton>
        <PhoneButtonContainer
          is_show={phone}
          onClick={() => {
            window.open(`tel:${phone}`);
          }}
        >
          <PhoneTextCallNowText>CALL NOW!</PhoneTextCallNowText>
          <PhoneText>{phone}</PhoneText>
        </PhoneButtonContainer>
      </ListItemRight>
    </ListItemContainer>
  );
};

const ListItemContainer = styled.div`
  width: calc(100% - 140px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 195px;
  position: relative;
  margin-top: 15px;
  padding: 0px 70px;
  border-radius: 20px;
  /* border-bottom: 4px solid ${theme.primary}; */
  box-shadow: 1px 1px 4px rgb(195 218 191 / 50%),
    2px 2px 8px rgb(218 242 214 / 40%), 5px 5px 16px rgb(237 246 236 / 30%);

  @media (max-width: 515px) {
    width: 100%;
    flex-direction: column;
    padding: 20px 0px;

    .MuiCollapse-root {
      width: 100% !important;
    }
  }
`;

const ListItemLeft = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  @media (max-width: 515px) {
    width: 100%;
  }
`;

const ListItemLeftLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 150px;
`;

const ListItemLeftLogo = styled.img`
  width: 100%;
`;

const ListItemLeftNSMLContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
`;

const ListItemLeftNSMLText = styled.p`
  font-size: 0.6em;
  color: #6e6e6e;
  margin: 0px;
  padding: 2px 0px;
`;

const ListItemLeftStarsListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 10px 0px;
  width: 100%;
`;

const ListItemLeftStarsFilledListContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  padding-bottom: 20px;
`;

const ListItemLeftStarsFilledListStarMask = styled.img`
  position: absolute;
  width: 180px;
  height: 40px;
  top: 0px;
  left: 0px;
  z-index: 3;
  @media (max-width: 515px) {
    top: 0px;
    left: calc(50% - 90px);
  }
`;

const ListItemLeftStarsFilled = styled.div`
  position: absolute;
  width: ${(p) => `${p.width}px`};
  height: 40px;
  top: 0px;
  left: 10px;
  background-color: ${(p) => p.color};
  z-index: ${(p) => p.z_index};
  @media (max-width: 515px) {
    top: 0px;
    left: calc(50% - 90px);
  }
`;

const ListItemMiddle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  @media (max-width: 515px) {
    width: 90%;
    margin: 0px 5%;
    align-items: flex-start;
  }
`;

const ContentPreviewContainer = styled.div`
  width: 80%;
  margin: 0px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 515px) {
    padding-bottom: 10px;
    width: 97.5%;
    margin: 0px 1.25%;
  }
`;

const ContentPreviewContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentPreviewContainerItemHeadText = styled.p`
  font-size: 10px;
  font-family: "Yantramanav", sans-serif;
  margin: 2.5px 0px;
`;

const ContentPreviewContainerItemText = styled.p`
  font-size: 20px;
  font-family: "Yantramanav", sans-serif;
  font-weight: 600;
  margin: 2.5px 0px;

  > span {
    font-size: 15px;
    font-family: "Yantramanav", sans-serif;
    font-weight: 400;
  }

  @media (max-width: 515px) {
    font-size: 15px;

    > span {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

const ContentResultContainer = styled.div`
  width: 80%;
  margin: 0px 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media (max-width: 515px) {
    padding-bottom: 10px;
    width: 100%;
    margin: 0px;
  }
`;

const ContentResultContainerText = styled.p`
  font-size: 10px;
  font-family: "Yantramanav", sans-serif;
  margin: 2.5px 0px;
`;

const ListItemRight = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 515px) {
    width: 100%;
  }
`;

const slide_in = keyframes`
  from {
    width: 0px;
  }

  to {
    width: 20px;
  }
`;

const slide_out = keyframes`
  from {
    width: 20px;
  }

  to {
    width: 0px;
  }
`;

const ViewRateButton = styled(Button)`
  background-color: #f48507 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-family: "Outfit", sans-serif;
  width: 140px;
  height: 50px;
  transition: 0.5s;
  color: #fff !important;

  .MuiButton-endIcon {
    width: ${(p) => (p.is_show_arrow ? "20px" : "0px")};
    height: 20px;
    animation: ${(p) => (p.is_show_arrow ? slide_in : slide_out)} 0.2s linear;
  }

  &:hover {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
`;

const PhoneButtonContainer = styled.div`
  display: ${(p) => (p.is_show ? "flex" : "none")};
  border: 1px solid #098b1e;
  transition: 0.2s;
  cursor: pointer;
  flex-direction: column;
  width: 140px;
  height: 45px;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
`;

const PhoneTextCallNowText = styled.p`
  color: #098b1e;
  font-size: 0.7em;
  text-transform: uppercase;
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const PhoneText = styled.p`
  color: #585858;
  margin-left: 5px;
  font-size: 0.9em;
  font-weight: 700;
  margin: 0px;
  font-size: 0.8rem;
  font-family: "Yantramanav", sans-serif;
`;

const IndexContainer = styled.div`
  background: ${theme.primary_light};
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  left: -10px;
`;

const IndexText = styled.p`
  font-family: "Yantramanav", sans-serif;
  color: #fff;
  font-size: 1.5rem;
  transform: translate(2px, 2px);
`;

const MobileToggleDetailContainer = styled.div`
  display: none;

  @media (max-width: 515px) {
    display: ${(p) => (p.is_show ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    border: 2px solid #2c99dd;
    padding: 4px 8px;
    margin: 10px auto 20px auto;
    cursor: pointer;
    font-size: 0.7em;
    text-transform: uppercase;
  }
`;

export default ListItem;
