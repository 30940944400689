import React, { useState, useImperativeHandle } from "react";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Message = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  useImperativeHandle(ref, () => ({
    handleOpen: () => {
      handleOpen();
    },
  }));

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      key={props.message}
      TransitionComponent={TransitionDown}
    >
      <Alert severity={props.type}>{props.message}</Alert>
    </Snackbar>
  );
});

export default Message;
