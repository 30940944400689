import styled from "styled-components";

const BackgroundBall = ({ size, direction, top, left, bottom }) => {
  return (
    <BackgroundBallWrapper
      size={size}
      direction={direction}
      top={top}
      left={left}
      bottom={bottom}
    />
  );
};

const BackgroundBallWrapper = styled.div`
  position: absolute;
  z-index: -40;
  top: ${(p) => `${p.top}vh`};
  bottom: ${(p) => `${p.bottom}vh`};
  left: ${(p) => `${p.left}vw`};
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  border-radius: ${(p) => `${p.size / 2}px`};
  background: rgb(123, 125, 224);
  background: ${(p) => `linear-gradient(
      ${p.direction}deg,
      rgba(152,170,133,1) 0%, rgba(185,199,169,1) 12%, rgba(234,244,223,1) 100%
    );`};
`;

export default BackgroundBall;
