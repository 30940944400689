import React from "react";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";

import { fetchToken, fetchData, fetchZipCodeFromIPAddress } from "../util/api";

import Basic from "../components/Basic";
import ListItem from "../components/DynamicDataListItem";
import Message from "../components/Message";
import loc from "../constant/location.json";

const DynamicOffer = ({ ppcid, cid }) => {
  let { default_loan_type } = useParams();
  const msg_ref = React.useRef(null);
  const [state, setState] = React.useState({
    loading: false,
    zip_code: "",
    loan_type: "",
    loan_amount: "",
    property_val: "",
    credit_score: 721,
    time_frame: "",
    is_veteran: false,
    data: [],
  });

  const handleToggleIsVeteran = () =>
    setState((state) => ({ ...state, is_veteran: !state.is_veteran }));

  const handleTraceClickDropDown = (val) => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleTraceClickDropDown(name);

    setState((state) => ({ ...state, [name]: value }));
  };

  const handleFiveDigit = (num) => {
    if (num.toString().length < 5) {
      let return_num_str = num.toString();
      let num_of_zero = 5 - num.toString().length;
      for (let i = 0; i < num_of_zero; i++) {
        return_num_str = "0" + return_num_str;
      }
      return return_num_str;
    } else {
      return num.toString();
    }
  };

  React.useEffect(() => {
    if (state.loan_type) {
      const tagManagerArgs = {
        dataLayer: {
          ppc_lander: "React Comparison Table",
          lander_type: "Bankrate",
          product_name: state.loan_type === "refi" ? "Refinance" : "Purchase",
          event: "comparisonTableLoad",
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [state.loan_type]);

  React.useEffect(() => {
    if (default_loan_type) {
      setState((state) => ({ ...state, loan_type: default_loan_type }));
    }
  }, [default_loan_type]);

  React.useEffect(() => {
    const handleFetchData = async () => {
      const token_data = await fetchToken();
      setState((state) => ({ ...state, loading: true }));
      const zip_code =
        loc.filter((i) => i.state === state.zip_code)[0]?.zip_code || 29707;

      if (token_data.message === "success") {
        const data = await fetchData(
          token_data.token,
          state.loan_type === "refi" ? "refinance" : "purchase",
          400000,
          300000,
          handleFiveDigit(zip_code) || 29707,
          state.credit_score,
          state.is_veteran ? "RegularMilitary" : "NoMilitaryService"
        );

        if (data.message === "success") {
          setState((state) => ({
            ...state,
            data: data.data.offers,
            loading: false,
          }));
        } else {
          msg_ref.current.handleOpen();
          setState((state) => ({
            ...state,
            data: [],
            loading: false,
          }));
        }
      }
    };
    handleFetchData();
  }, [
    state.zip_code,
    state.loan_type,
    state.property_val,
    state.credit_score,
    state.is_veteran,
  ]);

  React.useEffect(() => {
    const handleFetchPostalCode = async () => {
      setState((state) => ({ ...state, loading: true }));
      const postal_code = await fetchZipCodeFromIPAddress(true);
      setState((state) => ({
        ...state,
        zip_code: postal_code,
        loading: false,
      }));
    };
    handleFetchPostalCode();
  }, []);

  // React.useEffect(() => {
  //   const handleUpdateData = async () => {
  //     setState((state) => ({ ...state, loading: true }));
  //     const res = await fetchStaticOfferData();
  //     const update_data = [];
  //     res.data.forEach((i) => {
  //       if (
  //         handleCheckIsDataHide(i.stateHide, state.zip_code) &&
  //         handleCheckIsDataHide(i.purposeHide, state.loan_type) &&
  //         handleCheckIsDataHide(i.purchTimeframe, state.time_frame) &&
  //         handleCheckIsGradualDataHide(i.creditHide, state.credit_score) &&
  //         handleCheckIsGradualDataHide(i.loanHide, state.loan_amount)
  //       ) {
  //         update_data.push(i);
  //       }
  //     });

  //     setState((state) => ({ ...state, data: update_data, loading: false }));
  //   };

  //   handleUpdateData();
  // }, [
  //   state.zip_code,
  //   state.loan_type,
  //   state.loan_amount,
  //   state.time_frame,
  //   state.credit_score,
  // ]);

  let list_content;

  if (state.loading) {
    list_content = <div className="loader" />;
  } else {
    list_content = state.data.map((i, index) => {
      return (
        <ListItem
          key={i.id}
          id={i.id}
          index={index + 1}
          logo={i.advertiser.logo.large}
          nmls={i.advertiser.nmlsLicense}
          phone={i.advertiser.phone}
          link={`${i.tracking.link}&clkid=${ppcid || 7}_${
            cid || 99999999
          }&utm_source=RateZip+API&pid=myfi_ratezip_api&utm_medium=partner`}
          rating={i.rate}
          apr={i.apr}
          monthly_payment={i.estimatedPayment}
          upfront_fees={i.upFrontCosts}
          star_rating={i.advertiser.reviews.averageRating}
          is_show_detail_btn={false}
          lender_name={i.advertiser.name}
        />
      );
    });
  }

  return (
    <React.Fragment>
      <Message ref={msg_ref} message={"no data was found"} type={"warning"} />
      <Basic
        list_content={list_content}
        loading={state.loading}
        handleChange={handleChange}
        handleToggleIsVeteran={handleToggleIsVeteran}
        parent_state={state}
        is_show_location={true}
        is_show_loan_type={true}
        is_show_credit_score={true}
        is_show_loan_amount={false}
        is_show_timeframe={false}
        search_console_item_num={3}
        is_show_powered_by_bankrate
        is_show_veteran={true}
        is_dynamic
      />
    </React.Fragment>
  );
};

export default DynamicOffer;
