import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";

import BackgroundTheme from "../components/BackgroundTheme";
import Modal from "../components/Modals";

import * as theme from "../util/theme";
import money_select_logo from "../assets/images/money_select_logo.png";

const PrivacyAndSecurityPage = ({ is_dynamic }) => {
  const advertising_disclosure_ref = React.useRef(null);
  const view_ref = React.useRef(null);
  const { page_id } = useParams();

  const [state, setState] = React.useState({
    view_height: 10000,
  });

  const handleOpenAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleOpen();
  };

  const handleCloseAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleClose();
  };

  const handleSendEmail = (email) => {
    window.location.href = email;
  };

  React.useEffect(() => {
    if (view_ref.current && view_ref.current.clientHeight) {
      setState((state) => ({
        ...state,
        view_height: view_ref.current.clientHeight,
      }));
    }
  }, []);

  return (
    <PrivacyAndSecurityPageWrapper ref={view_ref}>
      <Modal ref={advertising_disclosure_ref} is_default_false>
        <AdvertisingDisclosureModalWrapper>
          <AdvertisingDisclosureCloseIconContainer>
            <AdvertisingDisclosureCloseIcon>
              <CloseIcon
                sx={{ color: theme.primary }}
                onClick={handleCloseAdvertisingClosureModal}
              />
            </AdvertisingDisclosureCloseIcon>
          </AdvertisingDisclosureCloseIconContainer>
          <AdvertisingDisclosureHeaderContainer>
            <AdvertisingDisclosureHeaderText>
              Advertising Disclosure
            </AdvertisingDisclosureHeaderText>
          </AdvertisingDisclosureHeaderContainer>
          <AdvertisingDisclosureModalContent>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                MoneySelect is a comparison shopping website. We earn revenue
                and advertising compensation from the reviews that we display.
                Our objective is to give consumers a trustworthy way to compare
                the best mortgage lenders in the nation at a given moment, all
                in one spot. All of the reviews are written by MoneySelect
                editorial staff. Inclusion on our comparison pages does not
                imply that we endorse the companies listed.
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                The ranking of each listing is an internal decision made by
                MoneySelect that is based on many factors including the
                reputation of the the lender, the engagement rate of consumers,
                the revenue earned from the lender and the capacity of the
                lender. If you have any concerns or suggestions, please contact
                us!
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            {(page_id === "3" || page_id === "4" || page_id === "5") && (
              <React.Fragment>
                <AdvertisingDisclosureHeaderContainer>
                  <AdvertisingDisclosureHeaderText>
                    Rates
                  </AdvertisingDisclosureHeaderText>
                </AdvertisingDisclosureHeaderContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    The rate information is obtained by Bankrate from the listed
                    institutions. Bankrate cannot guarantee the accuracy or
                    availability of any rates shown. Institutions may have
                    different rates on their own websites than those posted on
                    Bankrate.com. The listings that appear on this page are from
                    companies from which this website receives compensation,
                    which may impact how, where, and in what order products
                    appear. This table does not include all companies or all
                    available products.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Consumer Satisfaction: Bankrate attempts to verify the
                    accuracy and availability of its Advertisers' terms through
                    its quality assurance process and requires Advertisers to
                    agree to our Terms and Conditions and to adhere to our
                    Quality Control Program. If you believe that you have
                    received an inaccurate quote or are otherwise not satisfied
                    with the services provided to you by the institution you
                    choose, please{" "}
                    <a href={"https://www.bankrate.com/contact/"}>
                      click here.
                    </a>
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Rate collection and criteria:{" "}
                    <a
                      href={
                        "https://www.bankrate.com/investing/product-criteria/?prodtype=invest"
                      }
                    >
                      Click here
                    </a>{" "}
                    for more information on rate collection and criteria.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
              </React.Fragment>
            )}
          </AdvertisingDisclosureModalContent>

          <AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentText>
              Sincerely, <br />
              The MoneySelect Team
            </AdvertisingDisclosureContentText>
          </AdvertisingDisclosureContentContainer>
          <AdvertisingDisclosureCloseButtonContainer>
            <AdvertisingDisclosureCloseButton
              onClick={handleCloseAdvertisingClosureModal}
            >
              Close
            </AdvertisingDisclosureCloseButton>
          </AdvertisingDisclosureCloseButtonContainer>
        </AdvertisingDisclosureModalWrapper>
      </Modal>
      <AppHeader>
        <AppHeaderLogoContainer>
          <AppLogo src={money_select_logo} />
        </AppHeaderLogoContainer>
        <AdvertisingDisclouserContainer>
          <AdvertisingDisclouserText
            onClick={handleOpenAdvertisingClosureModal}
            className={"advertising_modal"}
          >
            Advertising Disclosure
          </AdvertisingDisclouserText>
        </AdvertisingDisclouserContainer>
      </AppHeader>
      <TextContentContainer>
        <TextRow>
          <TextHead>About Us</TextHead>
        </TextRow>
        <TextBlock>
          <TextRow>
            <TextSubHead>
              When it comes to your money, select savings. Select security.
              Select better finances.
            </TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              We created MoneySelect as a financial comparison tool to easily
              allow people to choose the financial products and companies that
              work best for them. Take back control of your money by shopping
              around for low rates, high savings, and better customer service.
              Here at MoneySelect, we bring financial companies to you, allowing
              you to compare multiple options for the same product all in one
              place. And because all of the companies listed on MoneySelect are
              vetted for security and licensing compliance, you can be confident
              that the company you choose will go above and beyond to serve you.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Who We Are</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              Founded in 2019, MoneySelect is run by a team of financially-savvy
              researchers, content creators, and mortgage professionals, who,
              with the help of an excellent tech and design team, have the goal
              of creating one place where anyone can easily find the financial
              products that work best for them. Whether you’re looking to
              refinance your existing mortgage or are just starting out on a
              home purchase journey, MoneySelect is here to help.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>What’s Next?</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              Currently, MoneySelect specializes in helping customers compare
              Mortgage, Refinance, and Home Equity companies, but we have big
              plans! Keep an eye out for our Personal Loans and Deposits
              comparison pages to keep on making smart financial decisions!
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Contacting Us</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              If there are any questions regarding this privacy policy you may
              contact us via email using the information below. <br />
              <span onClick={() => handleSendEmail("paul@moneyselect.com")}>
                paul@moneyselect.com
              </span>
            </Text>
          </TextRow>
        </TextBlock>
      </TextContentContainer>
      <Footer>
        <FooterContent>
          <FooterDown>
            <FooterDownText>
              © 2022 Moneyselect.com. All Rights Reserved. <br />
              <span>Advertising Disclosure</span> |{" "}
              <span>Privacy & Security</span>
            </FooterDownText>
          </FooterDown>
        </FooterContent>
      </Footer>
      <BackgroundTheme height={state.view_height} />
    </PrivacyAndSecurityPageWrapper>
  );
};

const AppHeader = styled.div`
  max-width: 1036px;
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;

  @media (max-width: 515px) {
    width: 95%;
  }
`;

const AppHeaderLogoContainer = styled.div`
  width: 100px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppLogo = styled.img`
  width: 100%;
`;

const AdvertisingDisclouserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0px;
  border-radius: 10px;
  cursor: pointer;
`;

const AdvertisingDisclouserText = styled.p`
  font-size: 1em;
  color: ${theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Yantramanav", sans-serif;
  font-weight: 900;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    color: ${theme.primary_light};
  }

  @media (max-width: 515px) {
    width: 180px;
  }
`;

const PrivacyAndSecurityPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  @media (max-width: 515px) {
    width: 100vw;
  }
`;

const TextContentContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 1px 1px 4px rgb(195 218 191 / 50%),
    2px 2px 8px rgb(218 242 214 / 40%), 5px 5px 16px rgb(237 246 236 / 30%);

  @media (max-width: 515px) {
    width: 95%;
  }
`;

const TextRow = styled.div`
  width: 90%;
  margin: 0px auto;
`;

const TextHead = styled.h2`
  font-family: "Yantramanav", sans-serif;
  font-size: 2rem;
  margin: 30px 0px 0px 0px;
`;

const TextBlock = styled.div`
  width: 100%;
`;

const TextSubHead = styled.h4`
  font-family: "Yantramanav", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

const Text = styled.p`
  font-family: "Yantramanav", sans-serif;
  > span {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Footer = styled.div`
  background: ${theme.primary};
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 0px 15px 0px;
  margin-top: 50px;
  @media (max-width: 515px) {
    width: 100vw;
  }
`;

const FooterContent = styled.div`
  padding: 0.5rem 0px;
  justify-content: center;
`;

const FooterDown = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FooterDownText = styled.p`
  color: #5f5f5f;
  font-size: 0.8em;
  text-align: center;
  > span {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AdvertisingDisclosureModalWrapper = styled.div`
  width: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 25%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  outline: 0;
  padding: 20px;
  max-height: 90vh;

  @media (max-width: 515px) {
    width: calc(100% - 70px);
    left: 15px;
  }
`;

const AdvertisingDisclosureModalContent = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const AdvertisingDisclosureCloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const AdvertisingDisclosureCloseIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AdvertisingDisclosureHeaderContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureHeaderText = styled.h5`
  font-weight: 500;
  margin: 0px;
  font-size: 1.25rem;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureContentContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureContentText = styled.p`
  font-size: 0.8em;
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AdvertisingDisclosureCloseButton = styled(Button)`
  background-color: ${theme.primary} !important;
  border: none;
  color: #fff !important;
  width: 75px;
  height: 36px;
  text-transform: capitalize;
  font-weight: 600 !important;
`;

export default PrivacyAndSecurityPage;
