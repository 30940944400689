import React from "react";
import { Routes, Route } from "react-router-dom";
import TagManager from "react-gtm-module";
// import Basic from "./components/Basic";
// import WithNewModal from "./components/WithNewModal";
import StaticOfferBasic from "./pages/StaticOffer";
import StaticOfferWithParams from "./pages/StaticOfferWithParams";
import StaticOfferWithModal from "./pages/StaticOfferWithModal";
import DynamicOffer from "./pages/DynamicOffer";
import DynamicOfferWithModal from "./pages/DynamicOfferWithModal";
import DynamicOfferWithParams from "./pages/DynamicOfferWithParams";
import PersonalLoan from "./pages/PersonalLoan";
import PersonalLoanNoAbstractApi from "./pages/PersonalLoanNoAbstractApi";
import PrivacyAndSecurityPage from "./pages/PrivacyAndSecurityPage";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Savings from "./pages/SavingPage";
import Error from "./pages/404";

import { useQuery } from "./hooks/useQuery";

export default function App() {
  let query = useQuery();

  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  return (
    <Routes>
      <Route
        path="/:page_id/privacy_and_security"
        exact
        element={<PrivacyAndSecurityPage />}
      />
      <Route
        path="/personal_loan"
        exact
        element={
          <PersonalLoan
            ppcid={query.get("ppcid")}
            gclid={query.get("gclid")}
            mscklid={query.get("mscklid")}
            fbclid={query.get("fbclid")}
            cid={query.get("cid") || "9999"}
          />
        }
      />
      <Route
        path="/personal_loan_no_default_location"
        exact
        element={
          <PersonalLoanNoAbstractApi
            ppcid={query.get("ppcid")}
            gclid={query.get("gclid")}
            mscklid={query.get("mscklid")}
            fbclid={query.get("fbclid")}
            cid={query.get("cid") || "9999"}
          />
        }
      />
      <Route
        path="/mortgage/1"
        exact
        element={
          <StaticOfferWithModal
            ppcid={query.get("ppcid")}
            gclid={query.get("gclid")}
            mscklid={query.get("mscklid")}
            fbclid={query.get("fbclid")}
            cid={query.get("cid") || "9999"}
          />
        }
      />
      {/* <Route path="/2" element={<WithNewModal />} /> */}
      <Route
        path="/mortgage/2/:default_loan_type"
        element={
          <StaticOfferWithParams
            ppcid={query.get("ppcid")}
            gclid={query.get("gclid")}
            mscklid={query.get("mscklid")}
            fbclid={query.get("fbclid")}
            cid={query.get("cid") || "9999"}
          />
        }
      />
      <Route
        path="/mortgage/3"
        element={
          <DynamicOffer ppcid={query.get("ppcid")} cid={query.get("cid")} />
        }
      />
      <Route
        path="/mortgage/4"
        element={
          <DynamicOfferWithModal
            ppcid={query.get("ppcid")}
            cid={query.get("cid")}
          />
        }
      />
      <Route
        path="/mortgage/5/:default_loan_type"
        element={
          <DynamicOfferWithParams
            ppcid={query.get("ppcid")}
            cid={query.get("cid")}
          />
        }
      />
      <Route exact path="/mortgage" element={<StaticOfferBasic />} />
      <Route exact path="/savings/compare" element={<Savings />} />

      {/* <Route
          path="/4"
          element={<Basic header_text={"top personal loans lenders"} />}
        /> */}
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
