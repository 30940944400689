import React from "react";
import styled, { keyframes } from "styled-components";
import { useWindowSize } from "../hooks/useWindowSize";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import uuid from "react-uuid";
import TagManager from "react-gtm-module";

import StaticListItemLogo from "./StaticListItemLogo";
import RatingMessage from "./RatingMessage";

import checked from "../assets/images/check.png";
import * as theme from "../util/theme";

const ListItem = ({
  id,
  logo,
  index,
  description_preview,
  description,
  link,
  is_show_collapse,
  is_show_detail_btn,
  nmls,
  lender_name,
  lender_id,
  bullets,
  rating,
  phone,
  head_line,
  sub_head_line,
  ppcid,
  gclid,
  mscklid,
  fbclid,
  cid,
  order,
}) => {
  const window_size = useWindowSize();
  const [state, setState] = React.useState({
    is_show_more: false,
    is_mobile_show_detail: false,
    is_show_arrow: false,
    is_show_message: false,
  });

  const handleOpenToggleMessage = () => {
    setState((state) => ({
      ...state,
      is_show_message: true,
    }));
  };

  const handleCloseToggleMessage = () => {
    setState((state) => ({
      ...state,
      is_show_message: false,
    }));
  };

  const handleToggleButtonArrowIcon = () => {
    setState((state) => ({ ...state, is_show_arrow: !state.is_show_arrow }));
  };

  const handleToggleMobileContent = () => {
    setState((state) => ({
      ...state,
      is_mobile_show_detail: !state.is_mobile_show_detail,
    }));
  };

  const handleTraceInfo = () => {
    const tagManagerArgs = {
      dataLayer: {
        lender_name: `${lender_name}(static)`,
        event: "reactPLoansClick",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    window.open(`${link.split("sPPCID").join(params)}`);
  };

  const params = React.useMemo(() => {
    const handleGenerateParams = () => {
      const update_params_list = [];
      if (ppcid) {
        update_params_list.push(ppcid);
      } else {
        update_params_list.push(7);
      }

      // if (gclid) {
      //   update_params_list.push(`gclid=${gclid}`);
      // }
      // if (mscklid) {
      //   update_params_list.push(`mscklid=${mscklid}`);
      // }
      // if (fbclid) {
      //   update_params_list.push(`fbclid=${fbclid}`);
      // }
      if (cid) {
        update_params_list.push(cid);
      } else {
        update_params_list.push(999999);
      }

      return update_params_list.join("_");
    };

    return handleGenerateParams();
  }, [ppcid, cid]);

  React.useEffect(() => {
    if (window_size[0] > 515) {
      setState((state) => ({ ...state, is_mobile_show_detail: true }));
    }
  }, [window_size]);

  let stars_rating =
    +rating.split("r")[1] < 10
      ? +rating.split("r")[1] * 10
      : +rating.split("r")[1];
  let stars_rating_content;

  if (stars_rating % 10 !== 0) {
    stars_rating_content = [...Array(parseInt(stars_rating / 10))].map((i) => (
      <Star key={uuid()} is_filled />
    ));
    stars_rating_content.push(<Star key={uuid()} is_half />);
    [...Array(5 - parseInt(stars_rating / 10) - 1)].forEach((i) => {
      stars_rating_content.push(<Star key={uuid()} />);
    });
  } else {
    stars_rating_content = [...Array(parseInt(stars_rating / 10))].map(() => (
      <Star is_filled key={uuid()} />
    ));
    [...Array(5 - parseInt(stars_rating / 10))].forEach((i) => {
      stars_rating_content.push(<Star key={uuid()} />);
    });
  }

  return (
    <ListItemContainer key={id}>
      <IndexOuterContainer>
        <IndexContainer>
          <IndexText>{index}</IndexText>
        </IndexContainer>
      </IndexOuterContainer>
      <ListItemLeft>
        <StaticListItemLogo logo={logo} />
        <RateMessageContainer>
          <RatingMessage open={state.is_show_message} />
        </RateMessageContainer>
        <RateContainer>
          <RateStarTextContainer>
            <RateStarText>{+stars_rating * 0.1}</RateStarText>
          </RateStarTextContainer>
          <RateStarContainer
            onMouseEnter={handleOpenToggleMessage}
            onMouseLeave={handleCloseToggleMessage}
          >
            <ListItemLeftStarsListContainer>
              {stars_rating_content}
            </ListItemLeftStarsListContainer>
            <RateStarRateZipRatingContainer>
              <p>
                <span>RateZip</span> score
              </p>
              {!state.is_show_message ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </RateStarRateZipRatingContainer>
          </RateStarContainer>
        </RateContainer>
      </ListItemLeft>
      <MobileToggleDetailContainer
        is_show={is_show_detail_btn}
        onClick={handleToggleMobileContent}
        className={"less_and_more_button_content_on_mobile"}
      >
        {state.is_mobile_show_detail ? "hide detail" : "show detail"}
      </MobileToggleDetailContainer>
      <Collapse
        in={state.is_mobile_show_detail}
        sx={window_size[0] > 515 ? { width: "60%" } : { width: "100%" }}
      >
        <ListItemMiddle>
          <HeadLineContainer is_show={head_line}>
            <HeadLineText>{head_line}</HeadLineText>
          </HeadLineContainer>
          <SubHeadLineContainer is_show={sub_head_line}>
            <SubHeadLineText>{sub_head_line}</SubHeadLineText>
          </SubHeadLineContainer>
          <ContentPreviewContainer>
            {bullets.length === 1 && bullets[0] === "" ? (
              <ContentPreviewRowContainer no_data key={"no_data"}>
                <ContentPreviewText no_data>
                  No Data. You can click <span>View Rate</span> to get more
                  detail.
                </ContentPreviewText>
              </ContentPreviewRowContainer>
            ) : (
              bullets.map((bullet) => (
                <ContentPreviewRowContainer key={bullet}>
                  <ContentPreviewImage src={checked} />
                  <ContentPreviewText
                    dangerouslySetInnerHTML={{ __html: bullet }}
                  />
                </ContentPreviewRowContainer>
              ))
            )}
          </ContentPreviewContainer>
        </ListItemMiddle>
      </Collapse>
      <ListItemRight>
        <ViewRateButton
          onMouseEnter={handleToggleButtonArrowIcon}
          onMouseLeave={handleToggleButtonArrowIcon}
          endIcon={state.is_show_arrow ? <ArrowForwardIcon /> : null}
          is_show_arrow={state.is_show_arrow}
          onClick={handleTraceInfo}
        >
          get my rate
        </ViewRateButton>
        <PhoneButtonContainer
          is_show={phone}
          onClick={() => {
            window.open(`tel:${phone}`);
          }}
        >
          <PhoneTextCallNowText>CALL NOW!</PhoneTextCallNowText>
          <PhoneText>{phone}</PhoneText>
        </PhoneButtonContainer>
      </ListItemRight>
    </ListItemContainer>
  );
};

const Star = ({ is_filled, is_half }) => {
  let path;
  if (is_filled) {
    path = "start_filled";
  } else if (is_half) {
    path = "start_half_filled";
  } else {
    path = "start_not_filled";
  }

  return (
    <ListItemLeftStarsImageContainer>
      <ListItemLeftStarsImage src={require(`../assets/images/${path}.png`)} />
    </ListItemLeftStarsImageContainer>
  );
};

const ListItemContainer = styled.div`
  width: calc(100% - 140px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 155px;
  position: relative;
  margin-top: 15px;
  padding: 0px 70px;
  border-radius: 20px;
  /* border-bottom: 4px solid ${theme.primary}; */
  box-shadow: 1px 1px 4px rgb(195 218 191 / 50%),
    2px 2px 8px rgb(218 242 214 / 40%), 5px 5px 16px rgb(237 246 236 / 30%);

  @media (max-width: 515px) {
    width: 100%;
    flex-direction: column;
    padding: 20px 0px;

    .MuiCollapse-root {
      width: 100% !important;
    }
  }
  &:hover {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 6px 6px 16px rgb(0 0 0 / 50%);
  }
`;

const ListItemLeft = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  @media (max-width: 1128px) {
    width: 30%;
  }

  @media (max-width: 515px) {
    width: 100%;
  }
`;

// const ListItemLeftLogoWebp = styled.source`
//   width: 100%;
//   srcset: ${(p) => p.srcset};
// `;

const RateContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto 0px;
  padding-top: 10px;
  transform: translateX(15px);
  z-index: 1;

  @media (max-width: 515px) {
    width: 50%;
    margin: 0px auto;
    transform: translateX(0px);
    padding-bottom: 20px;
  }
`;

const RateMessageContainer = styled.div`
  position: absolute;
  top: 130px;
  z-index: 10;
`;

const RateStarContainer = styled.div`
  width: 70%;
`;

const RateStarRateZipRatingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
  width: 100%;

  > p {
    cursor: pointer;
    font-size: 10px;
    font-family: "Yantramanav", sans-serif;
    line-height: 11px;
    letter-spacing: 0.1px;
    margin: 0px;
    > span {
      font-weight: 500;
    }
  }
`;

const RateStarTextContainer = styled.div`
  width: 20%;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RateStarText = styled.h3`
  line-height: 33px;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -3px;
  margin: 0px;
  font-family: "Barlow", sans-serif !important;
  transform: translate(-5px, -2px);
`;

const ListItemLeftStarsListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 10px 0px 0px 0px;
  width: 100%;
  transform: translateY(5px);
`;

const ListItemLeftStarsImageContainer = styled.div`
  width: 20px;
`;

const ListItemLeftStarsImage = styled.img`
  width: 100%;
`;

const ListItemMiddle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-left: 20px;
  @media (max-width: 515px) {
    width: 90%;
    margin: 0px 5%;
    align-items: flex-start;
  }
`;

const HeadLineContainer = styled.div`
  display: ${(p) => (p.is_show ? "flex" : "none")};
  width: 90%;
  margin: 0px 5% 3px 5%;
`;

const HeadLineText = styled.h3`
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const SubHeadLineContainer = styled.div`
  display: ${(p) => (p.is_show ? "flex" : "none")};
  width: 90%;
  margin: 5px 5% 10px 5%;
`;

const SubHeadLineText = styled.h4`
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const ContentPreviewContainer = styled.div`
  width: 90%;
  margin: 0px 5%;
  @media (max-width: 515px) {
    padding-bottom: 10px;
  }
`;

const ContentPreviewRowContainer = styled.div`
  display: flex;
  justify-content: ${(p) => (p.no_data ? "center" : "flex-start")};
  align-items: center;
`;

const ContentPreviewText = styled.p`
  font-family: "Yantramanav", sans-serif;
  font-size: ${(p) => (p.no_data ? "1em" : "0.8em")};
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 1em;
  padding: 6px 0px;
  margin: 0px;
  color: ${(p) => (!p.no_data ? "#888888" : "#a8a8a8")};

  > span {
    color: ${(p) => (!p.no_data ? "#888888" : "#1c5caf")};
    font-weight: ${(p) => (!p.no_data ? 400 : 600)};
  }
`;

const ContentPreviewImage = styled.img`
  width: 15px;
  padding-right: 10px;
`;

const ListItemRight = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 515px) {
    width: 100%;
  }
`;

const slide_in = keyframes`
  from {
    width: 0px;
  }

  to {
    width: 20px;
  }
`;

const slide_out = keyframes`
  from {
    width: 20px;
  }

  to {
    width: 0px;
  }
`;

const ViewRateButton = styled(Button)`
  background-color: #f48507 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-family: "Yantramanav", sans-serif;
  width: 140px;
  height: 50px;
  transition: 0.5s;
  color: #fff !important;

  .MuiButton-endIcon {
    width: ${(p) => (p.is_show_arrow ? "20px" : "0px")};
    height: 20px;
    animation: ${(p) => (p.is_show_arrow ? slide_in : slide_out)} 0.2s linear;
  }

  &:hover {
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
`;

const PhoneButtonContainer = styled.div`
  display: ${(p) => (p.is_show ? "flex" : "none")};
  border: 1px solid #098b1e;
  transition: 0.2s;
  cursor: pointer;
  flex-direction: column;
  width: 140px;
  height: 45px;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
`;

const PhoneTextCallNowText = styled.p`
  color: #098b1e;
  font-size: 0.7em;
  text-transform: uppercase;
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const PhoneText = styled.p`
  color: #585858;
  margin-left: 5px;
  font-size: 0.9em;
  font-weight: 700;
  margin: 0px;
  font-size: 0.8rem;
`;

const IndexOuterContainer = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  border-radius: 16px;
`;

const IndexContainer = styled.div`
  background: ${theme.primary_light};
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  left: -10px;
`;

const IndexText = styled.p`
  font-family: "Yantramanav", sans-serif;
  color: #fff;
  font-size: 1.5rem;
  transform: translate(2px, 2px);
`;

const MobileToggleDetailContainer = styled.div`
  display: none;

  @media (max-width: 515px) {
    display: ${(p) => (p.is_show ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    border: 2px solid #2c99dd;
    padding: 4px 8px;
    margin: 10px auto 20px auto;
    cursor: pointer;
    font-size: 0.7em;
    text-transform: uppercase;
  }
`;

const ArrowUpIcon = styled(KeyboardArrowUp)`
  width: 20px !important;
  height: 20px !important;
`;

const ArrowDownIcon = styled(KeyboardArrowDownIcon)`
  width: 20px !important;
  height: 20px !important;
`;

export default ListItem;
