import styled from "styled-components";

const StaticListItemLogo = ({ logo }) => {
  return (
    <LogoContainer>
      <LogoContainerWebp>
        <source srcSet={`../assets/images/${logo}`} type="image/webp" />
        <Logo src={require(`../assets/images/${logo}`)} />
      </LogoContainerWebp>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 150px;
`;

const Logo = styled.img`
  width: 100%;
`;

const LogoContainerWebp = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 150px;
`;

export default StaticListItemLogo;
