import styled from "styled-components";

import CreditCard from "./CreditCard";
import BackgroundBall from "./BackgroundBall";

const BackgroundTheme = ({ height }) => {
  return (
    <BackgroundBallContainer height={height}>
      <CreditCard width={50} direction={-2} left={-5} bottom={50} is_donut />
      <CreditCard width={50} direction={-2} left={-5} top={74} is_donut />
      <CreditCard width={50} direction={2} left={88} top={50} is_donut />
      <CreditCard width={280} direction={5} left={87} top={20} />
      <BackgroundBall size={400} direction={45} left={90} top={4} />
      <BackgroundBall size={100} direction={90} left={90} top={50} />
      <BackgroundBall size={20} direction={75} left={87} top={45} />
      <BackgroundBall size={8} direction={65} left={90} top={70} />
      <CreditCard width={400} direction={-5} left={-5} top={75} />
      <BackgroundBall size={200} direction={45} left={-5} top={60} />
      <BackgroundBall size={100} direction={45} left={10} top={65} />
      <BackgroundBall size={50} direction={45} left={20} top={70} />
      <BackgroundBall size={400} direction={45} left={82} bottom={20} />
      <BackgroundBall size={100} direction={90} left={90} bottom={30} />
      <BackgroundBall size={20} direction={75} left={85} bottom={25} />
      <BackgroundBall size={8} direction={65} left={90} bottom={20} />
      <BackgroundBall size={10} direction={65} left={90} bottom={10} />
      <BackgroundBall size={15} direction={65} left={90} bottom={15} />
      <CreditCard width={370} direction={8} left={-10} bottom={120} />
      <CreditCard width={400} direction={-5} left={90} bottom={30} />
    </BackgroundBallContainer>
  );
};

const BackgroundBallContainer = styled.div`
  position: absolute;
  width: 100%;
  height: ${(p) => (p.height ? `${p.height}px` : "100vh")};
  overflow: hidden;
  z-index: -200;
`;

export default BackgroundTheme;
