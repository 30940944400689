import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import BackgroundTheme from "../components/BackgroundTheme";

import * as theme from "../util/theme";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <ErrorWrapper>
      <ErrorContent>
        <TextContainer>
          <ErrorBoldTextContainer>
            <ErrorBoldText>404</ErrorBoldText>
          </ErrorBoldTextContainer>
          <ErrorTextContainer>
            <ErrorText>
              this page could not be found. <br />
              back to{" "}
              <span
                onClick={() => {
                  navigate("/");
                }}
              >
                homepage
              </span>
              .
            </ErrorText>
          </ErrorTextContainer>
        </TextContainer>
      </ErrorContent>
      <ErrorMask />
      <BackgroundTheme />
      <ErrorBackgroundTheme />
    </ErrorWrapper>
  );
};

const ErrorContent = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  height: 50px;

  @media (max-width: 515px) {
    flex-direction: column;
  }
`;

const ErrorBoldTextContainer = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-right: 0.5px solid ${theme.primary};

  @media (max-width: 515px) {
    border-right: none;
  }
`;

const ErrorBoldText = styled.h4`
  font-weight: 600;
  line-height: 1.2;
  font-size: 2.4rem;
  margin: 0px;
  letter-spacing: -1px;
  font-family: "Yantramanav", sans-serif;
  color: ${theme.primary};
  text-align: center;
`;

const ErrorTextContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-left: 20px;
`;

const ErrorText = styled.p`
  margin: 0px;
  letter-spacing: 0.5px;
  font-family: "Yantramanav", sans-serif;
  color: ${theme.primary};
  text-align: start;
  margin: 0px;
  > span {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${theme.primary_light};
    }
  }
`;

const ErrorWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ErrorMask = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  backdrop-filter: blur(30px);
`;

const ErrorBackgroundTheme = styled.div`
  background: rgb(152, 170, 133);
  background: linear-gradient(
    90deg,
    rgba(152, 170, 133, 0.5) 0%,
    rgba(185, 199, 169, 0.5) 12%,
    rgba(234, 244, 223, 0.5) 39%,
    rgba(236, 246, 226, 0.5) 67%,
    rgba(237, 244, 230, 0.5) 94%,
    rgba(255, 255, 255, 0.5) 100%
  );
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  position: absolute;
`;

export default ErrorPage;
