import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";

import { fetchToken, fetchData, fetchZipCodeFromIPAddress } from "../util/api";

import Basic from "../components/Basic";
import ListItem from "../components/DynamicDataListItem";
import Modal from "../components/Modals";
import Message from "../components/Message";

import loc from "../constant/location.json";
import purch from "../assets/images/purch.png";
import refi from "../assets/images/refi.png";
import * as theme from "../util/theme";

const StaticOfferBasic = ({ ppcid, cid }) => {
  const modal_ref = React.useRef(null);
  const msg_ref = React.useRef(null);
  let { default_loan_type } = useParams();
  const [state, setState] = React.useState({
    loading: false,
    zip_code: "",
    loan_type: "",
    loan_amount: "",
    property_val: "",
    credit_score: 721,
    time_frame: "",
    is_veteran: false,
    data: [],
  });

  const handleFiveDigit = (num) => {
    if (num.toString().length < 5) {
      let return_num_str = num.toString();
      let num_of_zero = 5 - num.toString().length;
      for (let i = 0; i < num_of_zero; i++) {
        return_num_str = "0" + return_num_str;
      }
      return return_num_str;
    } else {
      return num.toString();
    }
  };

  const handleToggleIsVeteran = () =>
    setState((state) => ({ ...state, is_veteran: !state.is_veteran }));

  const handleTraceClickDropDown = (val) => {};

  const handleTraceModalButton = (val) => {
    const tagManagerArgs = {
      dataLayer: {
        product_name: `${val}`,
        event: "reactMortgageModalClick",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleTraceClickDropDown(name);

    setState((state) => ({ ...state, [name]: value }));
  };

  const handleUpdateLoanType = (val) => {
    const display_val = val === "refi" ? "refinance" : "purchase";
    handleTraceModalButton(display_val);
    setState((state) => ({ ...state, loan_type: val }));
    modal_ref.current.handleClose();
  };

  React.useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        ppc_lander: "React Comparison Table",
        lander_type: "Bankrate",
        product_name: "Modal",
        event: "comparisonTableLoad",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    if (default_loan_type) {
      setState((state) => ({ ...state, loan_type: default_loan_type }));
    }
  }, [default_loan_type]);

  React.useEffect(() => {
    const handleFetchData = async () => {
      const token_data = await fetchToken();
      setState((state) => ({ ...state, loading: true }));
      const zip_code =
        loc.filter((i) => i.state === state.zip_code)[0]?.zip_code || 29707;

      if (token_data.message === "success") {
        const data = await fetchData(
          token_data.token,
          state.loan_type === "refi" ? "refinance" : "purchase",
          400000,
          300000,
          handleFiveDigit(zip_code) || 29707,
          state.credit_score,
          state.is_veteran ? "RegularMilitary" : "NoMilitaryService"
        );

        if (data.message === "success") {
          setState((state) => ({
            ...state,
            data: data.data.offers,
            loading: false,
          }));
        } else {
          msg_ref.current.handleOpen();
          setState((state) => ({
            ...state,
            data: [],
            loading: false,
          }));
        }
      }
    };
    handleFetchData();
  }, [
    state.zip_code,
    state.loan_type,
    state.property_val,
    state.credit_score,
    state.is_veteran,
  ]);

  React.useEffect(() => {
    const handleFetchPostalCode = async () => {
      setState((state) => ({ ...state, loading: true }));
      const postal_code = await fetchZipCodeFromIPAddress(true);

      setState((state) => ({
        ...state,
        zip_code: postal_code,
        loading: false,
      }));
    };
    handleFetchPostalCode();
  }, []);

  let list_content;

  if (state.loading) {
    list_content = <div className="loader" />;
  } else {
    list_content = state.data.map((i, index) => {
      return (
        <ListItem
          key={i.id}
          id={i.id}
          index={index + 1}
          logo={i.advertiser.logo.large}
          nmls={i.advertiser.nmlsLicense}
          phone={i.advertiser.phone}
          link={`${i.tracking.link}&clkid=${ppcid || 7}_${
            cid || 99999999
          }&utm_source=RateZip+API&pid=myfi_ratezip_api&utm_medium=partner`}
          rating={i.rate}
          apr={i.apr}
          monthly_payment={i.estimatedPayment}
          upfront_fees={i.upFrontCosts}
          star_rating={i.advertiser.reviews.averageRating}
          is_show_detail_btn={false}
          lender_name={i.advertiser.name}
        />
      );
    });
  }

  return (
    <React.Fragment>
      <Message ref={msg_ref} message={"no data was found"} type={"warning"} />
      <Modal ref={modal_ref}>
        <ModalContainer>
          <ModalHeaderContainer>
            <ModalHeaderText>
              What type of <span>home loan</span> are you looking for?
            </ModalHeaderText>
          </ModalHeaderContainer>
          <ModalButtonContainer>
            <StyledButton
              className={"modal_refi_button_static"}
              onClick={() => handleUpdateLoanType("refi")}
            >
              <ButtonLogo alt="button_icon_refinance" src={refi} />
              <p>Refinance</p>
            </StyledButton>
            <StyledButton
              className={"modal_purchase_button_static"}
              onClick={() => handleUpdateLoanType("purch")}
            >
              <ButtonLogo alt="button_icon_purchase" src={purch} />
              <p>purchase</p>
            </StyledButton>
          </ModalButtonContainer>
          <DescriptionContainer>
            <DescriptionText>
              By using our website, you agree that you have read RateZip's Terms
              of Use and Privacy Notice and consent to both the processing of
              your personal information and the storing of cookies on your
              device to enhance site navigation, analyze site usage, and assist
              in our marketing efforts
            </DescriptionText>
          </DescriptionContainer>
        </ModalContainer>
      </Modal>
      <Basic
        list_content={list_content}
        loading={state.loading}
        handleChange={handleChange}
        handleToggleIsVeteran={handleToggleIsVeteran}
        parent_state={state}
        is_show_location={true}
        is_show_loan_type={true}
        is_show_credit_score={true}
        is_show_loan_amount={false}
        is_show_timeframe={false}
        search_console_item_num={3}
        is_show_powered_by_bankrate
        is_show_veteran={true}
        is_dynamic
      />
    </React.Fragment>
  );
};

const ModalContainer = styled.div`
  width: 40%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  position: absolute;
  top: 20%;
  left: 30%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1300px) {
    width: 60%;
    left: 20%;
  }

  @media (max-width: 515px) {
    width: 90vw;
    left: 5vw;
  }
`;

const ModalHeaderContainer = styled.div`
  @media (max-width: 515px) {
    width: 90%;
  }
`;

const ModalHeaderText = styled.h5`
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.25rem;
  > span {
    color: ${theme.primary};
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  @media (max-width: 515px) {
    flex-direction: column;
  }
`;

const StyledButton = styled.div`
  display: inline-block;
  color: #ffff;
  padding: 10px 15px;
  text-transform: uppercase;
  vertical-align: center;
  margin: 10px 30px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 0px;
  display: flex;
  align-items: center;
  background-color: ${theme.button_orange} !important;
  border-radius: 14px;

  > p {
    margin: 10px 0px;
    font-size: 1.3em;
    padding-left: 5px;
  }
`;

const ButtonLogo = styled.img`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  padding: 0px 15px 15px 15px;
`;

const DescriptionText = styled.p`
  color: #5f5f5f;
  font-size: 0.8em;
`;

export default StaticOfferBasic;
