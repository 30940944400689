import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from "react-router-dom";
// import { fetchToken, fetchData } from "../util/api";
// import { useWindowScrollPositions } from "../hooks/useWindowScrollPositions";
import { useWindowSize } from "../hooks/useWindowSize";

import Modal from "../components/Modals";
import Select from "./CustomMuiSelect";
import BackgroundTheme from "./BackgroundTheme";

import month from "../constant/month.json";
import * as theme from "../util/theme";

import money_select_logo from "../assets/images/money_select_logo.png";
function Basic({
  loan_type,
  header_text,
  list_content,
  handleChange,
  parent_state,
  is_show_location,
  is_show_loan_type,
  is_show_credit_score,
  is_show_loan_amount,
  is_show_timeframe,
  search_console_item_num,
  is_show_powered_by_bankrate,
  is_show_veteran,
  handleToggleIsVeteran,
  is_dynamic,
}) {
  const view_ref = React.useRef(null);
  const advertising_disclosure_ref = React.useRef(null);
  const window_size = useWindowSize();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  let navigate = useNavigate();
  const location = useLocation();
  const current_path = location.pathname.split("/")[1];

  const [state, setState] = React.useState({
    loading: false,
    is_mobile_menu_show: false,
    view_height: 10000,
  });

  const handleTraceMobileDropDown = (val) => {};

  const handleToggleMobileMenu = () => {
    handleTraceMobileDropDown();
    setState((state) => ({
      ...state,
      is_mobile_menu_show: !state.is_mobile_menu_show,
    }));
  };

  const handleOpenAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleOpen();
  };

  const handleCloseAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleClose();
  };

  const handleMobileOnChange = (e) => {
    if (window_size[0] < 550) {
      handleToggleMobileMenu();
    }
    handleChange(e);
  };

  // React.useEffect(() => {
  //   if (window_size[0] > 515) {
  //     setState((state) => ({ ...state, is_mobile_menu_show: true }));
  //   }
  // }, [window_size]);

  // React.useEffect(() => {
  //   if (loan_type) {
  //     setState((state) => ({ ...state, loan_type: loan_type }));
  //   }
  // }, [loan_type]);

  React.useEffect(() => {
    if (view_ref.current && view_ref.current.clientHeight) {
      setState((state) => ({
        ...state,
        view_height: view_ref.current.clientHeight,
      }));
    }
  }, [parent_state]);

  return (
    <AppWrapper ref={view_ref}>
      <Modal ref={advertising_disclosure_ref} is_default_false>
        <AdvertisingDisclosureModalWrapper>
          <AdvertisingDisclosureCloseIconContainer>
            <AdvertisingDisclosureCloseIcon>
              <CloseIcon
                sx={{ color: theme.primary }}
                onClick={handleCloseAdvertisingClosureModal}
              />
            </AdvertisingDisclosureCloseIcon>
          </AdvertisingDisclosureCloseIconContainer>
          <AdvertisingDisclosureHeaderContainer>
            <AdvertisingDisclosureHeaderText>
              Advertising Disclosure
            </AdvertisingDisclosureHeaderText>
          </AdvertisingDisclosureHeaderContainer>
          <AdvertisingDisclosureModalContent>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                MoneySelect is a comparison shopping website. We earn revenue
                and advertising compensation from the reviews that we display.
                Our objective is to give consumers a trustworthy way to compare
                the best mortgage lenders in the nation at a given moment, all
                in one spot. All of the reviews are written by MoneySelect
                editorial staff. Inclusion on our comparison pages does not
                imply that we endorse the companies listed.
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                The ranking of each listing is an internal decision made by
                MoneySelect that is based on many factors including the
                reputation of the the lender, the engagement rate of consumers,
                the revenue earned from the lender and the capacity of the
                lender. If you have any concerns or suggestions, please contact
                us!
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            {is_dynamic && (
              <React.Fragment>
                <AdvertisingDisclosureHeaderContainer>
                  <AdvertisingDisclosureHeaderText>
                    Rates
                  </AdvertisingDisclosureHeaderText>
                </AdvertisingDisclosureHeaderContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    The rate information is obtained by Bankrate from the listed
                    institutions. Bankrate cannot guarantee the accuracy or
                    availability of any rates shown. Institutions may have
                    different rates on their own websites than those posted on
                    Bankrate.com. The listings that appear on this page are from
                    companies from which this website receives compensation,
                    which may impact how, where, and in what order products
                    appear. This table does not include all companies or all
                    available products.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Consumer Satisfaction: Bankrate attempts to verify the
                    accuracy and availability of its Advertisers' terms through
                    its quality assurance process and requires Advertisers to
                    agree to our Terms and Conditions and to adhere to our
                    Quality Control Program. If you believe that you have
                    received an inaccurate quote or are otherwise not satisfied
                    with the services provided to you by the institution you
                    choose, please{" "}
                    <a href={"https://www.bankrate.com/contact/"}>
                      click here.
                    </a>
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Rate collection and criteria:{" "}
                    <a
                      href={
                        "https://www.bankrate.com/investing/product-criteria/?prodtype=invest"
                      }
                    >
                      Click here
                    </a>{" "}
                    for more information on rate collection and criteria.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
              </React.Fragment>
            )}
          </AdvertisingDisclosureModalContent>

          <AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentText>
              Sincerely, <br />
              The MoneySelect Team
            </AdvertisingDisclosureContentText>
          </AdvertisingDisclosureContentContainer>
          <AdvertisingDisclosureCloseButtonContainer>
            <AdvertisingDisclosureCloseButton
              onClick={handleCloseAdvertisingClosureModal}
            >
              Close
            </AdvertisingDisclosureCloseButton>
          </AdvertisingDisclosureCloseButtonContainer>
        </AdvertisingDisclosureModalWrapper>
      </Modal>
      <AppHeader>
        <AppHeaderLogoContainer>
          <AppLogo src={money_select_logo} />
        </AppHeaderLogoContainer>
        <AdvertisingDisclouserContainer>
          <AdvertisingDisclouserText
            onClick={handleOpenAdvertisingClosureModal}
            className={"advertising_modal"}
          >
            Advertising Disclosure
          </AdvertisingDisclouserText>
        </AdvertisingDisclouserContainer>
      </AppHeader>

      {/* <SeenOnContainer>
        <SeenOnLogoContainer></SeenOnLogoContainer>
      </SeenOnContainer> */}
      <ContentContainer>
        <HeaderTextContainer>
          <HeaderText>
            We compare and review the <br />
            <span>{header_text || "top mortgage sites"}</span> in your area for{" "}
            {month[currentMonth]} {currentYear}
          </HeaderText>
        </HeaderTextContainer>
        <SubHeaderTextContainer>
          <SubHeaderText>
            Compare offers from multiple lenders before applying for a loan. Get
            the cash you need fast from the best personal loans lenders in your
            area.
          </SubHeaderText>
        </SubHeaderTextContainer>
        <SearchContainers is_mobile_menu_show={state.is_mobile_menu_show}>
          <SearchTextContainers>
            <SearchText>Which mortgage lender is right for you?</SearchText>
          </SearchTextContainers>

          <SearchSelectionContainer>
            <MobileSearchIconContainer onClick={handleToggleMobileMenu}>
              <p>open search console</p>
              <MobileSearchIcon />
            </MobileSearchIconContainer>
            <Select
              label="Location"
              className={"location_selection"}
              onChange={handleMobileOnChange}
              value={parent_state.zip_code}
              name={"zip_code"}
              is_show={is_show_location}
              size={"small"}
            >
              <MenuItem value="" disabled>
                Select ...
              </MenuItem>
              <MenuItem value="AL">Alabama</MenuItem>
              <MenuItem value="AK">Alaska</MenuItem>
              <MenuItem value="AZ">Arizona</MenuItem>
              <MenuItem value="AR">Arkansas</MenuItem>
              <MenuItem value="CA">California</MenuItem>
              <MenuItem value="CO">Colorado</MenuItem>
              <MenuItem value="CT">Connecticut</MenuItem>
              <MenuItem value="DE">Delaware</MenuItem>
              <MenuItem value="DC">District Of Columbia</MenuItem>
              <MenuItem value="FL">Florida</MenuItem>
              <MenuItem value="GA">Georgia</MenuItem>
              <MenuItem value="HI">Hawaii</MenuItem>
              <MenuItem value="ID">Idaho</MenuItem>
              <MenuItem value="IL">Illinois</MenuItem>
              <MenuItem value="IN">Indiana</MenuItem>
              <MenuItem value="IA">Iowa</MenuItem>
              <MenuItem value="KS">Kansas</MenuItem>
              <MenuItem value="KY">Kentucky</MenuItem>
              <MenuItem value="LA">Louisiana</MenuItem>
              <MenuItem value="ME">Maine</MenuItem>
              <MenuItem value="MD">Maryland</MenuItem>
              <MenuItem value="MA">Massachusetts</MenuItem>
              <MenuItem value="MI">Michigan</MenuItem>
              <MenuItem value="MN">Minnesota</MenuItem>
              <MenuItem value="MS">Mississippi</MenuItem>
              <MenuItem value="MO">Missouri</MenuItem>
              <MenuItem value="MT">Montana</MenuItem>
              <MenuItem value="NE">Nebraska</MenuItem>
              <MenuItem value="NV">Nevada</MenuItem>
              <MenuItem value="NH">New Hampshire</MenuItem>
              <MenuItem value="NJ">New Jersey</MenuItem>
              <MenuItem value="NM">New Mexico</MenuItem>
              <MenuItem value="NY">New York</MenuItem>
              <MenuItem value="NC">North Carolina</MenuItem>
              <MenuItem value="ND">North Dakota</MenuItem>
              <MenuItem value="OH">Ohio</MenuItem>
              <MenuItem value="OK">Oklahoma</MenuItem>
              <MenuItem value="OR">Oregon</MenuItem>
              <MenuItem value="PA">Pennsylvania</MenuItem>
              <MenuItem value="RI">Rhode Island</MenuItem>
              <MenuItem value="SC">South Carolina</MenuItem>
              <MenuItem value="SD">South Dakota</MenuItem>
              <MenuItem value="TN">Tennessee</MenuItem>
              <MenuItem value="TX">Texas</MenuItem>
              <MenuItem value="UT">Utah</MenuItem>
              <MenuItem value="VT">Vermont</MenuItem>
              <MenuItem value="VA">Virginia</MenuItem>
              <MenuItem value="WA">Washington</MenuItem>
              <MenuItem value="WV">West Virginia</MenuItem>
              <MenuItem value="WI">Wisconsin</MenuItem>
              <MenuItem value="WY">Wyoming</MenuItem>
            </Select>
            <Select
              label={"Loan Purpose"}
              onChange={handleMobileOnChange}
              className={"loan_type_selection"}
              value={parent_state.loan_type}
              name={"loan_type"}
              is_show={is_show_loan_type}
              size={"small"}
              title={
                window_size[0] > 515 &&
                "Many lenders consider loan purpose when deciding whether to approve your loan."
              }
            >
              <MenuItem value="" disabled>
                Select ...
              </MenuItem>
              <MenuItem value="debt">Debt Consolidation</MenuItem>
              <MenuItem value="credit_card">Credit Card Consolidation</MenuItem>
              <MenuItem value="home_improvement">Home Improvement</MenuItem>
              <MenuItem value="student_loan">Student Loan</MenuItem>
              <MenuItem value="medical_expenses">Medical Expenses</MenuItem>
              <MenuItem value="major_purchase">Major Purchase</MenuItem>
              <MenuItem value="vacation">Vacation</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            <Select
              label={"Credit Score"}
              className={"credit_score_selection"}
              onChange={handleMobileOnChange}
              value={parent_state.credit_score}
              name={"credit_score"}
              is_show={is_show_credit_score}
              size={"small"}
              title={
                window_size[0] > 515 &&
                "Some of the lenders listed specialize in high loan amounts."
              }
            >
              <MenuItem value="" disabled>
                Select ...
              </MenuItem>
              <MenuItem value={721}>Excellent (720-850)</MenuItem>
              <MenuItem value={691}>Good (690-719)</MenuItem>
              <MenuItem value={631}>Fair (630-689)</MenuItem>
              <MenuItem value={350}>Poor (350-629)</MenuItem>
            </Select>
            <Select
              label={"Loan Amount"}
              className={"loan_amount_selection"}
              onChange={handleMobileOnChange}
              value={parent_state.loan_amount}
              name={"loan_amount"}
              is_show={is_show_loan_amount}
              size={"small"}
              title={
                window_size[0] > 515 &&
                "The higher your credit score, the more likely you are to qualify, but some of the lenders listed below specialize in fair and even poor credit personal loans."
              }
            >
              <MenuItem value="" disabled>
                Select ...
              </MenuItem>
              <MenuItem value={5000}>Under $5K</MenuItem>
              <MenuItem value={15000}>$5K to $15K</MenuItem>
              <MenuItem value={35000}>$15K to $35K</MenuItem>
              <MenuItem value={35001}>$35K or more</MenuItem>
            </Select>
            <Select
              label={"Timeframe?"}
              className={"timeframe_selection"}
              onChange={handleMobileOnChange}
              value={parent_state.time_frame}
              name={"time_frame"}
              is_show={is_show_timeframe}
              size={"small"}
            >
              <MenuItem value="" disabled>
                Select ...
              </MenuItem>
              <MenuItem value="asap">ASAP</MenuItem>
              <MenuItem value="soon">Soon</MenuItem>
              <MenuItem value="just_looking">Just Looking</MenuItem>
            </Select>
          </SearchSelectionContainer>
        </SearchContainers>

        <ResultListContainer>
          {list_content.length === 0 ? <p>No Match Data</p> : list_content}
        </ResultListContainer>
        <DisclosureContainer>
          <DisclosureText>
            The third-party advertisers and lenders listed on MoneySelect’s
            personal loan comparison page may offer personal loans with rates
            that range from 2% APR to 35.99% APR with terms from 2 months to 84
            months. These rates and terms are subject to change without notice
            and are controlled by our third-party advertisers and lenders, not
            by MoneySelect, and actual rates may differ from advertised rates
            based on the eligibility criteria set by the lender, which includes
            factors such as credit score, loan amount, loan term, and credit
            usage and history. Other fees may apply to these personal loan
            offers, including origination fees or late payment fees. Please see
            the lender’s terms and conditions for more information. The personal
            loan offers listed on MoneySelect will require your application to
            and approval by the lender. Therefore, it is possible that you may
            not qualify for a personal loan or may not qualify for the highest
            loan amount or lowest rates. Approvals and applications are not
            controlled by MoneySelect. MoneySelect may receive compensation from
            these lenders or third-party advertisers for listing a lender or
            third-party advertiser on this page. An example of a personal loan
            repayment is as follows: For a $15,000 personal loan with a 48 month
            term, and APRs ranging from 2% to 35.99%, assuming all payments are
            made on-time, the monthly payments would range from $325 to $594,
            and the total amount paid would range from $15,620.49 to $28,492.
          </DisclosureText>
        </DisclosureContainer>
        <PowerByContainer is_show={is_show_powered_by_bankrate}>
          <PowerByText>Powered by:</PowerByText>
          <PowerByLink href="https://www.bankrate.com/">
            <PowerByImage
              src={
                "https://ratezip.com/rate-quotes/bankrate-demo/img/Bankrate.png"
              }
              alt={"Bankrate Logo"}
            />
          </PowerByLink>
          <PowerByText>Bankrate, LLC NMLS #1427381</PowerByText>
          <PowerByText>BR Tech Services, Inc. NMLS #1743443</PowerByText>
        </PowerByContainer>
      </ContentContainer>
      <Footer>
        <FooterContent>
          <FooterDown>
            <FooterDownText>
              © 2022 Moneyselect.com. All Rights Reserved. <br />
              <span onClick={handleOpenAdvertisingClosureModal}>
                Advertising Disclosure
              </span>{" "}
              |{" "}
              <span
                onClick={() => {
                  navigate(`/${current_path}/privacy_and_security`);
                }}
              >
                Privacy & Security
              </span>
            </FooterDownText>
          </FooterDown>
        </FooterContent>
      </Footer>
      <ContactUsContainer>
        <ContactUsText>
          Call (866) 362-7999 to Speak to our Featured Lender
        </ContactUsText>
      </ContactUsContainer>
      <BackgroundTheme height={state.view_height} />
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 515px) {
    width: 100vw;
  }
`;

const AppHeader = styled.div`
  max-width: 1036px;
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  @media (max-width: 515px) {
    width: 95%;
  }
`;

const AppHeaderLogoContainer = styled.div`
  width: 100px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppLogo = styled.img`
  width: 100%;
`;

const AdvertisingDisclouserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0px;
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 515px) {
    display: none;
  }
`;

const AdvertisingDisclouserText = styled.p`
  font-size: 1em;
  color: ${theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Yantramanav", sans-serif;
  font-weight: 900;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    color: ${theme.primary_light};
  }

  @media (max-width: 515px) {
    width: 180px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1036px;
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 515px) {
    width: 95%;
  }
`;

const HeaderTextContainer = styled.div`
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  @media (max-width: 1128px) {
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
    text-align: center;
  }

  @media (max-width: 515px) {
    width: 95%;
  }
`;

const SubHeaderTextContainer = styled.div`
  width: 80%;
  @media (max-width: 515px) {
    display: none;
  }
`;

const HeaderText = styled.h2`
  font-weight: 600;
  line-height: 1.2;
  font-size: 2.4rem;
  margin: 0px;
  letter-spacing: -1px;
  font-family: "Yantramanav", sans-serif;

  > span {
    color: ${theme.primary};
  }

  @media (max-width: 1208px) {
    text-align: left;
  }

  @media (max-width: 515px) {
    font-size: 1.2rem;
    letter-spacing: 0px;
  }
`;

const SubHeaderText = styled.h4`
  color: ${theme.primary_light};
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.2;
  margin: 10px 0px;
  font-family: "Yantramanav", sans-serif;
`;

const SearchContainers = styled.div`
  /* background-color: #dfd6f1; */
  width: 100%;
  margin: 0px auto 20px auto;
  height: 100px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 515px) {
    position: absolute;
    top: ${(p) => (p.is_mobile_menu_show ? "0px" : "-65vh")};
    left: 0px;
    background-color: ${theme.primary_light};
    z-index: 100;
    height: 65vh;
    border-radius: 0px 0px 40px 40px;
    transition: top 0.5s ease-in;
  }
`;

const SearchTextContainers = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 515px) {
    width: 85%;
    margin: 0px auto;
  }
`;

const SearchText = styled.p`
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.2;
  margin: 10px 0px;
  font-family: "Yantramanav", sans-serif;
`;

const SearchSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1208px) {
    width: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 515px) {
    display: flex;
    width: 85%;
    margin: 0px auto;
    flex-direction: column;
  }
`;

const ResultListContainer = styled.div`
  width: 100%;
  min-height: 60vh;
  > p {
    padding: 20px 0px;
    text-align: center;
  }
  @media (max-width: 1208px) {
    padding: 70px 0px;
  }
`;

const Footer = styled.div`
  background: ${theme.primary_light};
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 0px 30px 0px;
  margin-top: 50px;
  @media (max-width: 515px) {
    width: 100vw;
  }
`;

const FooterContent = styled(ContentContainer)`
  justify-content: center;
`;

const FooterDown = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FooterDownText = styled.p`
  color: #5f5f5f;
  font-size: 0.8em;
  text-align: center;
  > span {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ContactUsContainer = styled.div`
  position: fixed;
  z-index: 5;
  width: 100%;
  background-color: #306494;
  color: #fff;
  padding: 8px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
`;

const ContactUsText = styled.p`
  font-size: 1.5em;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  margin: 10px 0px;
`;

const AdvertisingDisclosureModalWrapper = styled.div`
  width: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 25%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  outline: 0;
  padding: 1rem;
  max-height: 90vh;

  @media (max-width: 515px) {
    width: 87%;
    left: 2%;
  }
`;

const AdvertisingDisclosureModalContent = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const AdvertisingDisclosureCloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const AdvertisingDisclosureCloseIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AdvertisingDisclosureHeaderContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureHeaderText = styled.h5`
  font-weight: 500;
  margin: 0px;
  font-size: 1.25rem;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureContentContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureContentText = styled.p`
  font-size: 0.8em;
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AdvertisingDisclosureCloseButton = styled(Button)`
  background-color: ${theme.primary} !important;
  border: none;
  color: #fff !important;
  width: 75px;
  height: 36px;
  text-transform: capitalize;
  font-weight: 600 !important;
`;

const PowerByContainer = styled.div`
  display: ${(p) => (p.is_show ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
`;

const PowerByText = styled.p`
  font-size: 10px;
  margin-bottom: 0px;
`;

const PowerByLink = styled.a``;

const PowerByImage = styled.img`
  height: 15px;
  transform: translateY(5px);
`;

const MobileSearchIconContainer = styled.div`
  display: none;
  @media (max-width: 515px) {
    position: absolute;
    height: 30px;
    bottom: -30px;
    right: 40px;
    background-color: ${theme.primary_light};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 8px 8px;
    padding: 0px 20px;
    > p {
      font-size: 1rem;
      color: #fff;
    }
  }
`;

const MobileSearchIcon = styled(SearchIcon)`
  color: #fff;
`;

const DisclosureContainer = styled.div`
  margin-top: 50px;
`;

const DisclosureText = styled.p`
  margin: 0px;
  font-size: 14px;
  font-family: "Yantramanav", sans-serif;
  font-weight: 400;
`;

// const StyledSelect = styled(Select)`
//   min-width: 150px !important;
//   border-radius: 30px !important;
//   padding: 5px 10px !important;
// `;

export default Basic;
