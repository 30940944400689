import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const TransitionsModal = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (props.is_default_false) {
      setOpen(false);
    } else {
      if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
        return;
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (props.is_default_false) {
      setOpen(false);
    }
  }, [props.is_default_false]);

  React.useImperativeHandle(ref, () => ({
    handleClose: () => {
      handleClose();
    },
    handleOpen: () => {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableAutoFocus
      >
        <Fade in={open}>{props.children}</Fade>
      </Modal>
    </div>
  );
});

export default TransitionsModal;
