import React from "react";
import styled from "styled-components";
import TagManager from "react-gtm-module";

import { fetchPersonalLoamStaticOfferData } from "../util/api";

import Basic from "../components/BasicForPersonalLoanPage";
import ListItem from "../components/PersonalLoanItem";
import Modal from "../components/SlideUpModals";
import Message from "../components/Message";

import * as theme from "../util/theme";

const PersonalLoan = ({ ppcid, gclid, mscklid, fbclid, cid }) => {
  const credit_score_modal_ref = React.useRef(null);
  // const loan_amount_modal_ref = React.useRef(null);
  const msg_ref = React.useRef(null);
  const [state, setState] = React.useState({
    loading: false,
    zip_code: "",
    loan_type: "other",
    loan_amount: 35001,
    property_val: "",
    credit_score: 691,
    time_frame: "",
    data: [],
  });

  const handleTraceModalButton = (val) => {
    const tagManagerArgs = {
      dataLayer: {
        product_name: `${val}_static`,
        event: "reactMortgageModalClick",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  const handleUpdateLoanType = (val) => {
    handleTraceModalButton(val);
    setState((state) => ({ ...state, credit_score: val }));
    credit_score_modal_ref.current.handleClose();
    // loan_amount_modal_ref.current.handleOpen();
  };

  // const handleUpdateLoanAmount = (val) => {
  //   setState((state) => ({ ...state, loan_amount: val }));
  //   loan_amount_modal_ref.current.handleClose();
  // };

  const handleCheckIsGradualDataHide = (number, selected_number) => {
    if (selected_number) {
      if (+selected_number < +number) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleCheckIsDataHide = (arr = [], selected_val) => {
    // console.log(arr);
    if (selected_val) {
      for (let i = 0; i <= arr.length; i++) {
        if (arr[i] === selected_val) {
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  };

  const handleTraceClickDropDown = (val) => {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleTraceClickDropDown(name);

    setState((state) => ({ ...state, [name]: value }));
  };

  React.useEffect(() => {
    credit_score_modal_ref.current.handleOpen();
  }, []);

  React.useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        ppc_lander: "React Comparison Table - Personal Loans",
        lander_type: "Static",
        product_name: "Modal",
        event: "comparisonTableLoad",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      setState((state) => ({ ...state, loading: true }));

      const res = await fetchPersonalLoamStaticOfferData();

      setState((state) => ({ ...state, data: res.data, loading: false }));
    };

    fetchData();
  }, []);

  // React.useEffect(() => {
  //   const handleFetchPostalCode = async () => {
  //     setState((state) => ({ ...state, loading: true }));
  //     const postal_code = await fetchZipCodeFromIPAddress(true);

  //     setState((state) => ({
  //       ...state,
  //       zip_code: postal_code,
  //       loading: false,
  //     }));
  //   };
  //   handleFetchPostalCode();
  // }, []);

  React.useEffect(() => {
    const handleUpdateData = async () => {
      setState((state) => ({ ...state, loading: true }));
      const res = await fetchPersonalLoamStaticOfferData();
      const update_data = [];
      res.data.forEach((i) => {
        if (
          handleCheckIsDataHide(i.stateHide, state.zip_code) &&
          handleCheckIsDataHide(i.purposeHide, state.loan_type) &&
          handleCheckIsGradualDataHide(i.creditHide, state.credit_score) &&
          handleCheckIsGradualDataHide(i.loanHide, state.loan_amount)
        ) {
          update_data.push(i);
        }
      });

      setState((state) => ({ ...state, data: update_data, loading: false }));
    };

    handleUpdateData();
  }, [
    state.zip_code,
    state.loan_type,
    state.loan_amount,
    state.time_frame,
    state.credit_score,
  ]);

  let list_content;

  if (state.loading) {
    list_content = <div className="loader" />;
  } else {
    list_content = state.data
      .sort((a, b) => b.order - a.order)
      .filter((i) => !i.hideCard)
      .map((i, index) => {
        let is_show_collapse;
        let is_show_detail_btn;

        if (i.bullets.length > 0) {
          is_show_detail_btn = true;
        } else {
          is_show_detail_btn = false;
        }

        return (
          <ListItem
            order={index}
            key={i.id}
            id={i.id}
            index={index + 1}
            logo={i.logo}
            bullets={i.bullets}
            is_show_collapse={is_show_collapse}
            is_show_detail_btn={is_show_detail_btn}
            nmls={i.nmls}
            phone={i.phone}
            link={i.link}
            rating={i.rating}
            stateHide={i.stateHide}
            creditHide={i.creditHide}
            purchTimeframe={i.purchTimeframe}
            purposeHide={i.purposeHide}
            sub_head_line={i.subHeadline}
            head_line={i.headline}
            ppcid={ppcid}
            gclid={gclid}
            mscklid={mscklid}
            fbclid={fbclid}
            cid={cid}
            lender_name={i.name}
          />
        );
      });
  }

  return (
    <React.Fragment>
      <Message ref={msg_ref} message={"no data was found"} type={"warning"} />
      <Modal ref={credit_score_modal_ref}>
        <ModalContainer>
          <ModalHeaderContainer>
            <ModalHeaderText>
              What is your <span>credit score</span>?
            </ModalHeaderText>
            <DescriptionText>
              (This will NOT affect your credit score)
            </DescriptionText>
          </ModalHeaderContainer>
          <ModalButtonContainer>
            <StyledButton
              className={"modal_refi_button_static"}
              onClick={() => handleUpdateLoanType(350)}
            >
              <p>Poor</p>
              <span>350-629</span>
            </StyledButton>
            <StyledButton
              className={"modal_purchase_button_static"}
              onClick={() => handleUpdateLoanType(631)}
            >
              <p>Fair</p>
              <span>630-689</span>
            </StyledButton>
          </ModalButtonContainer>
          <ModalButtonContainer>
            <StyledButton
              className={"modal_refi_button_static"}
              onClick={() => handleUpdateLoanType(691)}
            >
              <p>Good</p>
              <span>690-719</span>
            </StyledButton>
            <StyledButton
              className={"modal_purchase_button_static"}
              onClick={() => handleUpdateLoanType(721)}
            >
              <p>Excellent</p>
              <span>720-850</span>
            </StyledButton>
          </ModalButtonContainer>
        </ModalContainer>
      </Modal>
      {/* <Modal ref={loan_amount_modal_ref}>
        <ModalContainer>
          <ModalHeaderContainer>
            <ModalHeaderText>
              What is your <span>loan amount</span>?
            </ModalHeaderText>
          </ModalHeaderContainer>
          <ModalButtonContainer>
            <StyledButton
              className={"modal_refi_button_static"}
              onClick={() => handleUpdateLoanAmount(350)}
            >
              <p>Up to $100K</p>
            </StyledButton>
            <StyledButton
              className={"modal_purchase_button_static"}
              onClick={() => handleUpdateLoanAmount(631)}
            >
              <p>$100K to $250K</p>
            </StyledButton>
          </ModalButtonContainer>
          <ModalButtonContainer>
            <StyledButton
              className={"modal_refi_button_static"}
              onClick={() => handleUpdateLoanAmount(691)}
            >
              <p>$250K to $400K</p>
            </StyledButton>
            <StyledButton
              className={"modal_purchase_button_static"}
              onClick={() => handleUpdateLoanAmount(721)}
            >
              <p>$400K or more</p>
            </StyledButton>
          </ModalButtonContainer>
        </ModalContainer>
      </Modal> */}
      <Basic
        list_content={list_content}
        loading={state.loading}
        handleChange={handleChange}
        parent_state={state}
        is_show_location={false}
        is_show_loan_type={true}
        is_show_credit_score={true}
        is_show_loan_amount={true}
        is_show_timeframe={false}
        search_console_item_num={3}
        is_show_veteran={false}
        header_text={"top personal loans lenders"}
      />
    </React.Fragment>
  );
};

const ModalContainer = styled.div`
  width: 40%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  position: absolute;
  top: 20%;
  left: 30%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2.5rem;

  @media (max-width: 1300px) {
    width: 70%;
    left: 15%;
  }

  @media (max-width: 515px) {
    width: 90vw;
    left: 5vw;
    top: 10%;
  }
`;

const ModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 515px) {
    width: 90%;
  }
`;

const ModalHeaderText = styled.h5`
  font-weight: 900;
  line-height: 1.2;
  font-size: 1.9rem;
  margin-bottom: 0px;
  font-family: "Yantramanav", sans-serif;
  > span {
    color: ${theme.primary};
  }
  @media (max-width: 515px) {
    font-size: 1.7rem;
    text-align: center;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  @media (max-width: 515px) {
    flex-direction: column;
  }
`;

const StyledButton = styled.div`
  display: inline-block;
  color: #fff !important;
  background-color: ${theme.button_orange} !important;
  padding: 5px 15px;
  text-transform: uppercase;
  vertical-align: center;
  margin: 10px 30px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  box-shadow: 1px 1px 4px rgb(195 218 191 / 50%),
    2px 2px 8px rgb(218 242 214 / 40%), 5px 5px 16px rgb(237 246 236 / 30%);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 14px !important;

  &:hover {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 1px 1px 4px rgb(195 218 191 / 50%),
      2px 2px 8px rgb(218 242 214 / 50%), 5px 5px 16px rgb(237 246 236 / 45%);
  }

  > p {
    margin: 2.5px 0px;
    font-size: 1.3em;
  }

  > span {
    margin: 2.5px 0px;
    font-size: 0.8em;
  }

  @media (max-width: 515px) {
    > p {
      font-size: 1.1em;
    }

    > span {
      font-size: 0.6em;
    }
  }
`;

const DescriptionText = styled.p`
  color: #5f5f5f;
  font-size: 0.8em;
`;

export default PersonalLoan;
