import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";

import BackgroundTheme from "../components/BackgroundTheme";
import Modal from "../components/Modals";

import * as theme from "../util/theme";
import money_select_logo from "../assets/images/money_select_logo.png";

const PrivacyAndSecurityPage = ({ is_dynamic }) => {
  const advertising_disclosure_ref = React.useRef(null);
  const view_ref = React.useRef(null);
  const { page_id } = useParams();

  const [state, setState] = React.useState({
    view_height: 10000,
  });

  const handleOpenAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleOpen();
  };

  const handleCloseAdvertisingClosureModal = () => {
    advertising_disclosure_ref.current.handleClose();
  };

  const handleSendEmail = (email) => {
    window.location.href = email;
  };

  React.useEffect(() => {
    if (view_ref.current && view_ref.current.clientHeight) {
      setState((state) => ({
        ...state,
        view_height: view_ref.current.clientHeight,
      }));
    }
  }, []);

  return (
    <PrivacyAndSecurityPageWrapper ref={view_ref}>
      <Modal ref={advertising_disclosure_ref} is_default_false>
        <AdvertisingDisclosureModalWrapper>
          <AdvertisingDisclosureCloseIconContainer>
            <AdvertisingDisclosureCloseIcon>
              <CloseIcon
                sx={{ color: theme.primary }}
                onClick={handleCloseAdvertisingClosureModal}
              />
            </AdvertisingDisclosureCloseIcon>
          </AdvertisingDisclosureCloseIconContainer>
          <AdvertisingDisclosureHeaderContainer>
            <AdvertisingDisclosureHeaderText>
              Advertising Disclosure
            </AdvertisingDisclosureHeaderText>
          </AdvertisingDisclosureHeaderContainer>
          <AdvertisingDisclosureModalContent>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                MoneySelect is a comparison shopping website. We earn revenue
                and advertising compensation from the reviews that we display.
                Our objective is to give consumers a trustworthy way to compare
                the best mortgage lenders in the nation at a given moment, all
                in one spot. All of the reviews are written by MoneySelect
                editorial staff. Inclusion on our comparison pages does not
                imply that we endorse the companies listed.
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentContainer>
              <AdvertisingDisclosureContentText>
                The ranking of each listing is an internal decision made by
                MoneySelect that is based on many factors including the
                reputation of the the lender, the engagement rate of consumers,
                the revenue earned from the lender and the capacity of the
                lender. If you have any concerns or suggestions, please contact
                us!
              </AdvertisingDisclosureContentText>
            </AdvertisingDisclosureContentContainer>
            {(page_id === "3" || page_id === "4" || page_id === "5") && (
              <React.Fragment>
                <AdvertisingDisclosureHeaderContainer>
                  <AdvertisingDisclosureHeaderText>
                    Rates
                  </AdvertisingDisclosureHeaderText>
                </AdvertisingDisclosureHeaderContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    The rate information is obtained by Bankrate from the listed
                    institutions. Bankrate cannot guarantee the accuracy or
                    availability of any rates shown. Institutions may have
                    different rates on their own websites than those posted on
                    Bankrate.com. The listings that appear on this page are from
                    companies from which this website receives compensation,
                    which may impact how, where, and in what order products
                    appear. This table does not include all companies or all
                    available products.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    All rates are subject to change without notice and may vary
                    depending on location. These quotes are from banks, thrifts,
                    and credit unions, some of whom have paid for a link to
                    their own website where you can find additional information.
                    Those with a paid link are our Advertisers. Those without a
                    paid link are listings we obtain to improve the consumer
                    shopping experience and are not Advertisers. To receive the
                    Bankrate.com rate from an Advertiser, please identify
                    yourself as a Bankrate customer. Bank and thrift deposits
                    are insured by the Federal Deposit Insurance Corp. Credit
                    union deposits are insured by the National Credit Union
                    Administration.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Consumer Satisfaction: Bankrate attempts to verify the
                    accuracy and availability of its Advertisers' terms through
                    its quality assurance process and requires Advertisers to
                    agree to our Terms and Conditions and to adhere to our
                    Quality Control Program. If you believe that you have
                    received an inaccurate quote or are otherwise not satisfied
                    with the services provided to you by the institution you
                    choose, please{" "}
                    <a href={"https://www.bankrate.com/contact/"}>
                      click here.
                    </a>
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
                <AdvertisingDisclosureContentContainer>
                  <AdvertisingDisclosureContentText>
                    Rate collection and criteria:{" "}
                    <a
                      href={
                        "https://www.bankrate.com/investing/product-criteria/?prodtype=invest"
                      }
                    >
                      Click here
                    </a>{" "}
                    for more information on rate collection and criteria.
                  </AdvertisingDisclosureContentText>
                </AdvertisingDisclosureContentContainer>
              </React.Fragment>
            )}
          </AdvertisingDisclosureModalContent>

          <AdvertisingDisclosureContentContainer>
            <AdvertisingDisclosureContentText>
              Sincerely, <br />
              The MoneySelect Team
            </AdvertisingDisclosureContentText>
          </AdvertisingDisclosureContentContainer>
          <AdvertisingDisclosureCloseButtonContainer>
            <AdvertisingDisclosureCloseButton
              onClick={handleCloseAdvertisingClosureModal}
            >
              Close
            </AdvertisingDisclosureCloseButton>
          </AdvertisingDisclosureCloseButtonContainer>
        </AdvertisingDisclosureModalWrapper>
      </Modal>
      <AppHeader>
        <AppHeaderLogoContainer>
          <AppLogo src={money_select_logo} />
        </AppHeaderLogoContainer>
        <AdvertisingDisclouserContainer>
          <AdvertisingDisclouserText
            onClick={handleOpenAdvertisingClosureModal}
            className={"advertising_modal"}
          >
            Advertising Disclosure
          </AdvertisingDisclouserText>
        </AdvertisingDisclouserContainer>
      </AppHeader>
      <TextContentContainer>
        <TextRow>
          <TextHead>Privacy and Security</TextHead>
        </TextRow>
        <TextBlock>
          <TextRow>
            <TextSubHead>Do we use cookies?</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              Yes. Cookies are small files that a site or its service provider
              transfers to your computer’s hard drive through your Web browser
              (if you allow) that enables the site’s or service provider’s
              systems to recognize your browser and capture and remember certain
              information. MoneySelect does not currently use cookies to store
              private user information in its pay-per-click lead system. The
              persistent storage that cookies provide are not useful for the
              MoneySelect’s lead system because users typically do not return
              once they’ve submitted their lead information. MoneySelect does
              however utilize cookie as part of general page tracking, both in
              its pay-per-click lead system and also in its primary website. The
              general tracking information only contains the standard tracking
              data that providers like Google Analytics collect and maintain
              with their free tracking scripts. MoneySelect does not explicitly
              retain private user information using cookies Google, as a third
              party vendor, uses cookies to serve ads on our site. Google’s use
              of the DART cookie enables it to serve ads to our users based on
              their visit to our site and other sites on the Internet. Users may
              opt out of the use of the DART cookie by visiting the Google ad
              and content network privacy policy.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>
              Do we disclose any information to outside parties?
            </TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              We may share your information with trusted third parties who
              assist us in operating our website, conducting our business, or
              servicing you, so long as those parties agree to keep this
              information confidential. We may also release your information
              when we believe release is appropriate to comply with the law,
              enforce our site policies, or protect ours or others’ rights,
              property, or safety. However, non-personally identifiable visitor
              information may be provided to other parties for marketing,
              advertising, or other uses. When you submit your request for rate
              quotes from one of our advertisers, you consent to be contacted by
              us or any of our advertisers through any means, even if you are
              already opted into the National Do Not Call List administered by
              the Federal Trade Commission, any state Do Not Call List or the Do
              Not Call List of any specific institution. If you do not want to
              be contacted, after submitting your request, you can be removed by
              requesting removal from the specific advertiser who is contacting
              you.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Third party links</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              Occasionally, at our discretion, we may include or offer third
              party products or services on our website. These third party sites
              have separate and independent privacy policies. We therefore have
              no responsibility or liability for the content and activities of
              these linked sites. Nonetheless, we seek to protect the integrity
              of our site and welcome any feedback about these sites.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>
              California Online Privacy Protection Act Compliance
            </TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              Because we value your privacy we have taken the necessary
              precautions to be in compliance with the California Online Privacy
              Protection Act. We therefore will not distribute your personal
              information to outside parties without your consent.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>
              Childrens Online Privacy Protection Act Compliance
            </TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              We are in compliance with the requirements of COPPA (Childrens
              Online Privacy Protection Act), we do not collect any information
              from anyone under 13 years of age. Our website, products and
              services are all directed to people who are at least 13 years old
              or older.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>
              European General Data Protection Regulation Compliance
            </TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              We have updated our privacy policy to be in compliance with the
              European General Data Protection Regulation (GDPR).
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Your Consent</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>By using our site, you consent to our privacy policy.</Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Opt-Out Policy</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              You may change or correct your personal information by contacting
              us at{" "}
              <span onClick={() => handleSendEmail("privacy@ratezip.com")}>
                privacy@ratezip.com
              </span>
              . In certain cases, your information may have already been sent to
              a licensed lender. In these cases you will also need to inform the
              lender(s) to update, amend or delete your records as well. You may
              at any time opt-out of any/all of our services by sending an email
              to{" "}
              <span onClick={() => handleSendEmail("privacy@ratezip.com")}>
                privacy@ratezip.com
              </span>
              . We will thereafter expunge your information from our database
              permanently. We will keep your information on record to comply
              with legal obligations, reconcile accounts or resolve disputes,
              unless you notify us that you do not wish for us to retain your
              information.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>In the Event of a Breach</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              In the unlikely event that a breach affects MoneySelect’s systems,
              consumers that might be affected will be notified as soon as
              possible within 72 hours of MoneySelect first becoming aware of
              the breach.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Changes to our Privacy Policy</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              If we decide to change our privacy policy, we will post those
              changes on this page, and/or update the Privacy Policy
              modification date below. Policy changes will apply only to
              information collected after the date of the change.This policy was
              last modified on 9/27/2021.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Privacy Policy Customer Pledge</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              We pledge to you, our customer, that we have made a dedicated
              effort to bring our privacy policy in line with the following
              important privacy laws and initiatives: Childrens Online Privacy
              Protection Act California Online Privacy Protection Act European
              General Data Protection Regulation.
            </Text>
          </TextRow>
        </TextBlock>
        <TextBlock>
          <TextRow>
            <TextSubHead>Contacting Us</TextSubHead>
          </TextRow>
          <TextRow>
            <Text>
              If there are any questions regarding this privacy policy you may
              contact us via email using the information below. <br />
              <span onClick={() => handleSendEmail("paul@moneyselect.com")}>
                paul@moneyselect.com
              </span>
            </Text>
          </TextRow>
        </TextBlock>
      </TextContentContainer>
      <Footer>
        <FooterContent>
          <FooterDown>
            <FooterDownText>
              © 2022 Moneyselect.com. All Rights Reserved. <br />
              <span>Advertising Disclosure</span> |{" "}
              <span>Privacy & Security</span>
            </FooterDownText>
          </FooterDown>
        </FooterContent>
      </Footer>
      <BackgroundTheme height={state.view_height} />
    </PrivacyAndSecurityPageWrapper>
  );
};

const AppHeader = styled.div`
  max-width: 1036px;
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;

  @media (max-width: 515px) {
    width: 95%;
  }
`;

const AppHeaderLogoContainer = styled.div`
  width: 100px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppLogo = styled.img`
  width: 100%;
`;

const AdvertisingDisclouserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0px;
  border-radius: 10px;
  cursor: pointer;
`;

const AdvertisingDisclouserText = styled.p`
  font-size: 1em;
  color: ${theme.primary};
  text-align: center;
  line-height: 1em;
  font-family: "Yantramanav", sans-serif;
  font-weight: 900;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    color: ${theme.primary_light};
  }

  @media (max-width: 515px) {
    width: 180px;
  }
`;

const PrivacyAndSecurityPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  @media (max-width: 515px) {
    width: 100vw;
  }
`;

const TextContentContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  box-shadow: 1px 1px 4px rgb(195 218 191 / 50%),
    2px 2px 8px rgb(218 242 214 / 40%), 5px 5px 16px rgb(237 246 236 / 30%);

  @media (max-width: 515px) {
    width: 95%;
  }
`;

const TextRow = styled.div`
  width: 90%;
  margin: 0px auto;
`;

const TextHead = styled.h2`
  font-family: "Yantramanav", sans-serif;
  font-size: 2rem;
  margin: 30px 0px 0px 0px;
`;

const TextBlock = styled.div`
  width: 100%;
`;

const TextSubHead = styled.h4`
  font-family: "Yantramanav", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

const Text = styled.p`
  font-family: "Yantramanav", sans-serif;
  > span {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Footer = styled.div`
  background: ${theme.primary};
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 0px 15px 0px;
  margin-top: 50px;
  @media (max-width: 515px) {
    width: 100vw;
  }
`;

const FooterContent = styled.div`
  padding: 0.5rem 0px;
  justify-content: center;
`;

const FooterDown = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FooterDownText = styled.p`
  color: #5f5f5f;
  font-size: 0.8em;
  text-align: center;
  > span {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const AdvertisingDisclosureModalWrapper = styled.div`
  width: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 25%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  outline: 0;
  padding: 20px;
  max-height: 90vh;

  @media (max-width: 515px) {
    width: calc(100% - 70px);
    left: 15px;
  }
`;

const AdvertisingDisclosureModalContent = styled.div`
  max-height: 500px;
  overflow-y: scroll;
`;

const AdvertisingDisclosureCloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const AdvertisingDisclosureCloseIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AdvertisingDisclosureHeaderContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureHeaderText = styled.h5`
  font-weight: 500;
  margin: 0px;
  font-size: 1.25rem;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureContentContainer = styled.div`
  padding: 0.5rem 0px;
`;

const AdvertisingDisclosureContentText = styled.p`
  font-size: 0.8em;
  margin: 0px;
  font-family: "Yantramanav", sans-serif;
`;

const AdvertisingDisclosureCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AdvertisingDisclosureCloseButton = styled(Button)`
  background-color: ${theme.primary} !important;
  border: none;
  color: #fff !important;
  width: 75px;
  height: 36px;
  text-transform: capitalize;
  font-weight: 600 !important;
`;

export default PrivacyAndSecurityPage;
