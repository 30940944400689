import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Info from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

import * as theme from "../util/theme";

const BootstrapInput = styled(InputBase)(() => ({
  "label + &": {
    // transform: "translateY(-10px)",
  },
  "@media (max-width: 515px)": {
    width: "100% !important",
  },
  "& .MuiInputBase-input": {
    minWidth: 100,
    borderRadius: 25,
    border: "2px solid #ced4da",
    fontSize: 16,
    padding: "10px 40px 10px 20px",
    fontFamily: "Yantramanav",
    "&:focus": {
      borderRadius: 25,
      borderColor: theme.primary_light,
      boxShadow: "0 0 0 0.2rem rgba(218,242,241,.25)",
    },
  },
}));

const StyledLabel = styled(InputLabel)(() => ({
  fontSize: 10,
  fontFamily: "Yantramanav",
  padding: "2px 0px",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledInfo = styled(Info)(() => ({
  width: 15,
  height: 15,
  transform: "translate(5px, 3px)",
}));

export default function CustomizedSelects(props) {
  return (
    <div style={{ margin: "5px 0px" }}>
      <StyledLabel>
        {props.label}
        {props.title && (
          <Tooltip title={props.title}>
            <StyledInfo />
          </Tooltip>
        )}
      </StyledLabel>
      <Select displayEmpty {...props} input={<BootstrapInput />}>
        {props.children}
      </Select>
    </div>
  );
}
